import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TablePagination,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlogs, getAllBlogs } from '../../action/blogAction';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ToastContainer, toast } from 'react-toastify';

const PreviewBlog = () => {
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const { getBlogs } = useSelector((state) => state.getBlogs);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [page, setPage] = useState(0);
  const [BlogDelete, setBlogDelete] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  const handleOpen = (post) => {
    console.log(post, "jjhh")
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour12: true
    });
    return formattedDate;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleDelete = (_id) => {
    setOpenDeleteDialog(true);
    setBlogDelete(_id);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteDialog(false);
    await dispatch(deleteBlogs(BlogDelete));
    dispatch(getAllBlogs());
    toast.error("Blog deleted successfully");
  };


  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
};

  return (
    <Container>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        margin: "25px 0px",
      }}>
        <TablePagination
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            color: "#422afb",
            maxWidth: "fit-content",


          }}
          rowsPerPageOptions={[3, 6, 12]}
          component="div"
          count={getBlogs?.data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {getBlogs?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((post) => (
        <Box key={post._id} style={webStyle.mainBox}>
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <img src={post.blogPost.image} alt='' style={webStyle.image} />
            </Grid>
            <Grid item lg={9}>
              <Box style={{
                padding: "19px",
                marginTop: "20px"
              }}>
                <Typography variant="h6" gutterBottom style={{ fontSize: "12px", fontWeight: 600 }}>
                  {post.blogPost.title}
                  <div  />
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: '11px', marginBottom: "20px" }}>
                  {post.blogPost.description.substring(0, 200)}
                </Typography>
                <Box marginLeft="auto" display="flex" justifyContent='end'>
                  <AccessTimeIcon fontSize="small" style={{ marginRight: 4 }} />
                  <Typography variant="body2" color="textSecondary">
                    {formatDate(post.blogPost.created_at)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box style={{ display: 'flex', justifyContent: 'end', gap: '4px', margin: '15px 0px' }}>
            <IconButton aria-label="edit" style={{ color: 'green' }} >
              <EditIcon style={{ fontSize: "19px" }} />
            </IconButton>
            <IconButton aria-label="delete" style={{ color: 'red' }}  onClick={() => handleDelete(post._id)} >
              <DeleteIcon style={{ fontSize: "19px" }} />
            </IconButton>
            <IconButton aria-label="delete" style={{ color: '#422afb', background: 'rgb(198, 215, 253)' }} onClick={() => handleOpen(post)}>
              <ChromeReaderModeOutlinedIcon style={{ fontSize: "19px" }} />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCancelDelete}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle style={{ background: "rgb(198, 215, 253)", color: 'rgb(66, 42, 251)', fontSize: '12px' }}>Delete Confirmation</DialogTitle>
        <DialogContent style={{ marginTop: "20px", fontSize: '12px' }}>
          Are you sure you want to delete this Blog?
        </DialogContent>
        <DialogActions>
          <Button startIcon={<ClearOutlinedIcon />} onClick={handleCancelDelete} style={{ backgroundColor: 'rgb(66, 42, 251)', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
            Cancel
          </Button>
          <Button startIcon={<DeleteIcon />} onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        {selectedPost && (
          <>
            <DialogTitle style={{fontSize:'14px', fontWeight:700,margin:"15px 0px"}}>{selectedPost.blogPost.title}</DialogTitle>
            <DialogContent style={{padding:"20px"}}>
              <div style={{maxWidth:'500px'}}>
              <img src={selectedPost.blogPost.image} alt='' style={{ height: "300px"  ,width:'100%'}} />
              </div>
             
              <Typography variant="body1" style={{fontSize:"11px" ,margin:"20px 0px" ,textAlign:"justify"}}>
                <div dangerouslySetInnerHTML={{__html:selectedPost.blogPost.description}} />
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
    
  );
};

export default PreviewBlog;

const webStyle = {
  mainBox: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "5px 20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",

    margin: "15px 0px"
  },
  image: {
    height: "150px",
    width: "100%",
    borderRadius: "8px",
    marginTop: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  modalImage: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginBottom: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  authorInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },

};
