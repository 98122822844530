import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanding } from '../../action/landingBgAction';
import { Box } from '@mui/material';

const PreviewLandingHerobg = () => {
  const dispatch = useDispatch();
  const { getlanding } = useSelector((state) => state.getlanding);

  useEffect(() => {
    dispatch(getLanding());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box>
        {getlanding ?.Banners?.map((item, index) => (
          <img key={index} src={item.image.url} alt='img' style={{ height: "100px" }} />
        ))}
      </Box>
    </React.Fragment>
  );
}

export default PreviewLandingHerobg;
