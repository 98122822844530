import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Navigation from "../../inc/navigationBar/navigation";
import "../Services/Services.css";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubCetegory } from "../../../../action/subCategory";
import backgroundImage from "../../../../assets/group-men-work-clothes.jpg";
import {
  Box,
} from "@mui/material";
import Footer from "../../inc/Footer/footer";
import BookingCheckOut from "../BookingsCheckOut/booking"
const Services = () => {
  const dispatch = useDispatch();
  const [subcategory, setSubcategory] = useState("");
  const { fetchAllSubCategory } = useSelector(
    (state) => state.fetchAllSubCategory
  );
  console.log(fetchAllSubCategory, "kkk");
  const { id } = useParams();
  const location = useLocation();
  console.log(location, "location");
  const { obj } = location.state || {};
  console.log(obj, "categoryObject");

  useEffect(() => {
    dispatch(fetchSubCetegory(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (fetchAllSubCategory) {
      setSubcategory(fetchAllSubCategory);
    }
  }, [fetchAllSubCategory]);

  return (
    <>
      <Navigation />
      <React.Fragment>
      <Box style={styles.heroSection} className="doorplusheroimg">
        <div style={styles.overlay}></div>
          </Box>
        <Box>
          <div className="Service-mob-pd" style={{ padding: "25px" }}>
            <Grid container spacing={1}>
              {subcategory?.getsubcategory?.map((subcategory, index) => (
                <Grid key={index} item xs={4} md={3} sm={3}>
                  <Card
                    className="services-mob-style"
                    elevation={0}
                    style={{
                      borderRadius: "5px",
                      height: "180px",
                      background: "lightpink",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={subcategory.image.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Card>
                  <Typography
                    style={{ color: "grey", fontSize: "15px", padding: "5px" }}
                    className="cus-bottom-title"
                  >
                    {subcategory.SubcategoryTitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </React.Fragment>

      <BookingCheckOut />
      <Footer />
    </>
  );
};

export default Services;

const styles = {
  heroSection: {
    position: "relative",
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 65.1%), url(${backgroundImage})`,
    WebkitBackgroundSize: "100%",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "450px",
    color: "white",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    content: "",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

  },
  darkOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: 'linear-gradient(to left, rgb(0 0 0 / 66%), rgba(0, 0, 0, 0.5))'
  },
  iconButton: {
    padding: "10px",
    borderRadius: "5px",
    color: "black",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  searchButton: {
    padding: "15px 25px",
    backgroundColor: "black",
    borderRadius: "5px",
    color: "white",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  SearchBox: {
    position: "absolute",
    bottom: "-11%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "19px",
    borderRadius: "10px",
    width: "90%",
  },
};
