import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import img from "../../../../assets/image (11)_0.png";
import './newInstall.css'

const Newinstall = () => {
  return (
   
      <Box >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "20px" }} className="mobileNewInstallstyle">
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: "9px" }}
            >
              <Grid item xs={12}>
                <Box style={{padding:"25px"}} className="mobileNewInstallstyleBox">
                <Typography style={NewInstallStyle.heading} className="mobileNewInstallstyleHeading">
                  New Installnation
                  
                </Typography>
                {/* <Typography style={NewInstallStyle.ChlidParagraph}>
                  At Door Plus, we understand the importance of a
                  well-maintained home. Our team of skilled professionals is
                  dedicated to providing top-notch home repair services at
                  affordable prices. From fixing leaky faucets to installing new
                  fixtures, we handle it all with precision and care. Trust Door
                  Plus for all your home repair needs and experience the
                  difference today!
                </Typography> */}
                <Typography style={NewInstallStyle.ChildParagraph2} className="newSubtitle" >
                Door Plus offers a wide range of new installation
                services.
                  {/* Planning to upgrade your home with new installations? Look no
                  further!  */}
                  {/*
                        to enhance the functionality and aesthetic appeal of
                
                   your space. Whether it's installing sleek new doors,
                  energy-efficient windows, or modern fixtures, our expert team
                  ensures flawless execution and outstanding results. Elevate
                  your living experience with Door Plus today! */}
                </Typography>
                </Box>
             
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Box
          className="mobileNewInstallstyleBoxcard"
            style={{
              marginTop: "10px",
              background: "rgb(242, 242, 242)",
              padding: "50px",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography
                className="mobileNewInstallstyleHeading"
                  variant="h4"
                  gutterBottom
                  style={NewInstallStyle.heading}
                >
                  Need Pure Drinking Water?
                </Typography>
                <Typography variant="h5" style={NewInstallStyle.item} className="mobileNewInstallstyleoffer">
                  Get Up to 30% Off on Water Purifiers
                </Typography>
                <Typography
                className="mobileNewInstallstylechildep"
                  variant="body1"
                  gutterBottom
                  style={NewInstallStyle.ChildParagraph}
                >
                  Having clean and safe drinking water is essential for good
                  health. Our advanced water purifiers ensure that your family
                  gets pure and healthy water every time. With multi-stage
                  filtration and UV purification, our purifiers remove
                  impurities, bacteria, and viruses, making water safe for
                  consumption.
                </Typography>
                <Button variant="contained" className="shopnow"style={NewInstallStyle.button}>
                  Shop Now
                </Button>
              </Grid>
              <Grid item xs={12} md={6} >
                <img
                className="mobileNewInstallstyleImg"
                  src={img}
                  alt="Water Purifier"
                  style={{ maxWidth: "100%", height: "370px", borderRadius:'10px',
                    
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
   
  );
};

export default Newinstall;

const NewInstallStyle = {
  heading: {
    fontSize: "32px",
    fontWeight: 700,
    color: "black",
    textTransform: "capitalize",
    marginBottom: "4px",
  },
  ChildParagraph: {
    fontSize: "13px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
  ChildParagraph2:{
    fontSize: "13px",
    fontWeight: "400",
    color: "black",
    maxWidth:"700px",
    textTransform: "capitalize",
  },
  item: {
    margin: "16px 0",
  },
  button: {
    backgroundColor: "black",
    color: "white",
    borderRadius:"0px",
    marginTop: "16px",
    padding:"17px 40px"
  },
};
