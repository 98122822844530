import { Button, Card, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import uploadimg from "../icon/image.png"
import {  CreateLanding } from '../../action/landingBgAction';
import { useDispatch } from "react-redux";
const LandingHerobg = () => {
  const dispatch = useDispatch();
  const [heroimg, setheroImg] = React.useState({
    file: null
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!heroimg.file) {
      return;
    }
    dispatch(CreateLanding(heroimg));
    setheroImg({
      file: null,
    });
  };
  const fileInputRef = React.useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file, "ffff")
    if (file) {
      setheroImg((prevState) => ({
        ...prevState,
        file: file,
      }));

    }
  };

  const handleRemoveImage = () => {
    setheroImg((prevState) => ({
      ...prevState,
      file: null,
    }));
  };
  return (
    <Grid container spacing={1}>
 <Grid item xs={6} sm={6} md={6}>
      <form onSubmit={handleSubmit}>
        <Card
          variant="outlined"
          style={{
            borderRadius: "20px",
            padding: "4px",
            margin: "14px",
            height: "284px",
            width: "98%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px"

          }}
        >
          {heroimg.file ? (
            <>
              <img src={heroimg.file} alt="Uploaded" style={{ height: "160px", marginBottom: "10px" }} />
              <Button onClick={handleRemoveImage} variant="outlined" color="secondary">
                Remove Image
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" style={{ marginBottom: "2px", color: "#4b4b4b", fontSize: "12px" }}>
                Upload Image
              </Typography>
              <Card
                variant="outlined"
                style={{
                  padding: "20px",
                  margin: "10px",
                  height: "160px",
                  border: "2px #4b4b4b dashed",
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <IconButton onClick={handleUploadButtonClick} color="primary">
                  <img
                    style={{
                      height: "100px"
                    }}
                    src={uploadimg} alt="upload img" />
                </IconButton>
              </Card>
            </>
          )}
        </Card>
        <Grid item lg={12} sm={12} xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
          <Button type="submit" variant="contained" color="primary" style={{
            background: "#4b4b4b", padding: "12px 34px", borderRadius: "10px"
          }} disabled={!heroimg.file}>
            Submit
          </Button>
        </Grid>
      </form>

    </Grid>
    </Grid>
   
  )
}

export default LandingHerobg