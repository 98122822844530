export const SUBCATEGORY_REQUEST = 'SUBCATEGORY_REQUEST';
export const SUBCATEGORY_SUCCESS = 'SUBCATEGORY_SUCCESS';
export const SUBCATEGORY_FAIL = ' SUBCATEGORY_FAIL';
export const SUBCATEGORY_RESET = 'SUBCATEGORY_RESET';

export const GET_SUBCATEGORY_REQUEST = 'GET_SUBCATEGORY_REQUEST';
export const GET_SUBCATEGORY_SUCCESS = 'GET_SUBCATEGORY_SUCCESS';
export const GET_SUBCATEGORY_FAIL = 'GET_SUBCATEGORY_FAIL';
export const GET_SUBCATEGORY_RESET = 'GET_SUBCATEGORY_RESET';

export const DELETE_SUBCATEGORY_REQUEST = 'DELETE_SUBCATEGORY_REQUEST';
export const DELETE_SUBCATEGORY_SUCCESS = 'DELETE_SUBCATEGORY_SUCCESS';
export const DELETE_SUBCATEGORY_FAIL = 'DELETE_SUBCATEGORY_FAIL';
export const DELETE_SUBCATEGORY_RESET = 'DELETE_SUBCATEGORY_RESET';

export const UPDATE_SUBCATEGORY_REQUEST = 'UPDATE_SUBCATEGORY_REQUEST';
export const UPDATE_SUBCATEGORY_SUCCESS = 'UPDATE_SUBCATEGORY_SUCCESS';
export const UPDATE_SUBCATEGORY_FAIL = 'UPDATE_SUBCATEGORY_FAIL';
export const UPDATE_SUBCATEGORY_RESET = 'UPDATE_SUBCATEGORY_RESET';