
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { loginUser } from "../../../../action/UserAuthActions";
import Logo from "../../../../assets/Logo.png"
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Link,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import loginImg from "../../../../assets/servicesbg.jpg";

const Login = () => {
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(formData));
    setFormData({
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (user && user?.user?.role) {
      if (user.user?.role === "admin") {
        navigate("/");
      } else if (user?.user?.role === "user") {
        navigate("/");
      }
    }
  }, [user, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '10px' }}
    >
      <Grid
      mt={2}
        container
        item
        xs={12}
        md={12}
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          position: 'relative',
        }}
      >
        <Grid item xs={12} md={6} sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={loginImg} alt="Fashion Model" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px', boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px" }} />
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0))', borderRadius: '20px' }} />
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: '40px' }}>
        <img src={Logo} alt='logo' style={{height:'48px', marginBottom:"12px"}} />
          {/* <Typography sx={{ fontWeight: 700, fontSize: '40px', color: 'black' }}>DoorPlus</Typography> */}
          <Typography sx={{ marginTop: '15px', marginBottom: '15px', fontWeight: 300, fontSize: "11px", maxWidth: "400px", width: "100%" }}>
            At Door Plus, we understand the importance of a
            well-maintained home. Our team of skilled professionals is
            dedicated to providing top-notch home repair services at
            affordable prices.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '0px', fontWeight:500}}>
            Please login to your account
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email Address"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Keep me signed in" />
              <Link component={RouterLink} to="/forgot-password" sx={{ color: 'black', textDecoration: 'none' }}>
                Forgot Password?
              </Link>
            </Box>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ marginTop: '20px', padding: '16px 0', backgroundColor: 'black', '&:hover': { backgroundColor: 'grey' } }}
            >
              Login
            </Button>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
