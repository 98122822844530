import React from 'react';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const ThemeToggle = ({ theme, toggleTheme }) => {
  return (
    <IconButton onClick={toggleTheme} color="inherit" 
    style={{
      margin: "0px 10px",
      fontSize: "25px",
      cursor: "pointer",
      color:'#422afb',
      background:'#c6d7fd',
      padding:"5px",
      borderRadius:"30px"
    }}>
      {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

export default ThemeToggle;
