import React, { useState, useEffect } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TablePagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { Frequentlyans, deleteFaq } from '../../action/FaqActions';
import { ToastContainer, toast } from 'react-toastify';

const FaqPrivews = () => {
    const dispatch = useDispatch();
    const { getFaq } = useSelector((state) => state.getFaq);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [faqDelete, setFaqDelete] = useState(0);

    useEffect(() => {
        dispatch(Frequentlyans());
    }, [dispatch]);

    const handleDelete = (_id) => {
        setOpenDeleteDialog(true);
        setFaqDelete(_id);
    };

    const handleConfirmDelete = async () => {
        setOpenDeleteDialog(false);
        await dispatch(deleteFaq(faqDelete));
        dispatch(Frequentlyans());
        toast.error("Faq deleted successfully");
    };

    const handleCancelDelete = () => {
        setOpenDeleteDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <React.Fragment>

            <Container style={{ marginTop: '20px', color: 'black', maxWidth: '950px', marginBottom: '50px' }} className='faqcondoorplus'>
                <div style={{
                    display: 'flex',
                    justifyContent: 'end',
                    margin: "25px 0px",
                }}>
                    <TablePagination
                        style={{
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            color: "#422afb",
                            //  background: " rgb(198, 215, 253) ",
                            maxWidth: "fit-content",


                        }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={getFaq?.data?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
                {getFaq?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((faq, index) => (
                    <Accordion key={index}
                        style={{ backgroundColor: 'white', color: 'black', marginBottom: '15px', padding: '2px', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    >
                        <AccordionSummary
                            expandIcon={<AddIcon style={{ color: '#422afb' }} />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography style={{ fontWeight: 500, fontSize: '12px' }}>{faq.Faq.Questions}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ fontWeight: 300, fontSize: '12px' }}>
                                {faq.Faq.Answers}
                            </Typography>
                        </AccordionDetails>
                        <Box style={{ display: 'flex', justifyContent: "end" }}>
                            <IconButton aria-label="edit" style={{ color: 'green' }}>
                                <EditIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => handleDelete(faq._id)} style={{ color: 'red' }}>
                                <DeleteIcon style={{ fontSize: "19px" }} />
                            </IconButton>
                        </Box>
                    </Accordion>
                
                ))}
               
            </Container>

            <Dialog
                open={openDeleteDialog}
                onClose={handleCancelDelete}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle style={{ background: "rgb(198, 215, 253)", color: 'rgb(66, 42, 251)', fontSize: '12px' }}>Delete Confirmation</DialogTitle>
                <DialogContent style={{ marginTop: "20px", fontSize: '12px' }}>
                    Are you sure you want to delete this item?
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<ClearOutlinedIcon />} onClick={handleCancelDelete} style={{ backgroundColor: 'rgb(66, 42, 251)', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Cancel
                    </Button>
                    <Button startIcon={<DeleteIcon />} onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white', fontSize: "12px", textTransform: "capitalize" }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment>
    );
}

export default FaqPrivews;
