import {
  CREATE_BOOKING_FAILURE,
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_RESET,
  CREATE_BOOKING_SUCCESS,
  GET_BOOKING_FAILURE,
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_RESET,
  UPDATE_BOOKING_SUCCESS
} from "../constant/BookingConstant";


export const BookingReducer = (
  state = { Booking: {}, loading: false },
  action
) => {
  switch (action.type) {
    case CREATE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        Booking: action.payload,
        isAuthenticated: true,
      };
    case CREATE_BOOKING_RESET:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
      };
    case CREATE_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
        Booking: null,
      };
    default:
      return state;
  }
};
export const getBookingReducer = (state = { getBooking: [] }, action) => {
  switch (action.type) {
    case GET_BOOKING_REQUEST:
      return {
        loading: true,
        getBooking: [],
      };
    case GET_BOOKING_SUCCESS:
      return {
        loading: false,
        getBooking: action.payload,
      };
    case GET_BOOKING_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const updateBookingReducer = (state = { updateBooking: {}, loading: false }, action) => {
  switch (action.type) {
    case UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false
      };
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        updateBooking: action.payload,
        isAuthenticated: true
      };
    case UPDATE_BOOKING_RESET:
      return {
        ...state,
        loading: false,
        isAuthenticated: true
      };
    case UPDATE_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
        updateBooking: null
      };
    default:
      return state;
  }
};
