
import {
    FAQ_REQUEST,
    FAQ_SUCCESS,
    FAQ_RESET,
    FAQ_FAIL,
    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
    DELETE_FAQ_REQUEST,
    DELETE_FAQ_SUCCESS,
    DELETE_FAQ_FAIL,
    DELETE_FAQ_RESET,
 
} from "../constant/FaqConstant";



export const FaqReducer = (
    state = { Faq: {}, loading: false },
    action
  ) => {
    switch (action.type) {
      case FAQ_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false,
        };
      case FAQ_SUCCESS:
        return {
          ...state,
          loading: false,
          Faq: action.payload,
          isAuthenticated: true,
        };
      case FAQ_RESET:
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
        };
      case FAQ_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          Faq: null,
        };
      default:
        return state;
    }
  };

  export const getFaqReducer = (state = { getFaq: [] }, action) => {
    switch (action.type) {
      case GET_FAQ_REQUEST:
        return {
          loading: true,
          getFaq: [],
        };
      case GET_FAQ_SUCCESS:
        return {
          loading: false,
          getFaq: action.payload,
        };
      case GET_FAQ_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };

  export const DeletedFaqReducer = (state = { DeletedFaq: {} }, action) => {
    switch (action.type) {
      case DELETE_FAQ_REQUEST:
        return {
          ...state,
          loading: true,
          isDeleted: false
        };
      case DELETE_FAQ_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: true
        };
      case DELETE_FAQ_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case DELETE_FAQ_RESET:
        return {
          ...state,
          isDeleted: false
        };
      default:
        return state;
    }
  };