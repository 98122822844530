import axios from "axios";
import siteConfig from "../siteConfig";
import { CREATE_BOOKING_FAILURE, CREATE_BOOKING_SUCCESS, GET_BOOKING_FAILURE, GET_BOOKING_REQUEST, GET_BOOKING_SUCCESS, UPDATE_BOOKING_FAILURE, UPDATE_BOOKING_REQUEST, UPDATE_BOOKING_SUCCESS } from "../constant/BookingConstant";


export const BookingCreate = (BookingData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_BOOKING_FAILURE });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token"),
            }
        };
        const { data } = await axios.post(
            `${siteConfig.base_URL}/api/v1/Booking`,
            BookingData,
            config
        );
        dispatch({ type: CREATE_BOOKING_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CREATE_BOOKING_FAILURE, payload: error.message });
    }
};

export const getUserBookings = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_BOOKING_REQUEST
        })
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        const { data } = await axios.get(`${siteConfig.base_URL}/api/v1/getBookings`, config)
        dispatch({
            type: GET_BOOKING_SUCCESS, payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_BOOKING_FAILURE, payload: error.message
        })

    }
}


export const BookingUpdate = (bookingId, updateData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BOOKING_REQUEST });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token"),
            }
        };
        const { data } = await axios.put(
            `${siteConfig.base_URL}/api/v1/Booking/${bookingId}`,
            updateData,
            config
        );
        dispatch({ type: UPDATE_BOOKING_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_BOOKING_FAILURE, payload: error.message });
    }
};

