import axios from "axios";
import siteConfig from "../siteConfig";
import { CREATE_LANDING_PAGE_FAILURE, CREATE_LANDING_PAGE_REQUEST, CREATE_LANDING_PAGE_SUCCESS, GET_LANDING_PAGE_FAILURE, GET_LANDING_PAGE_REQUEST, GET_LANDING_PAGE_SUCCESS } from "../constant/LandingBannerConst";

export const CreateLanding = (formData) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_LANDING_PAGE_REQUEST });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem("token"),
        },
      };
      const { Data } = await axios.post(
        `${siteConfig.base_URL}/api/v1/admin/Banner/new`,
        formData,
        config
      );
      dispatch({ type: CREATE_LANDING_PAGE_SUCCESS, payload: Data });
    } catch (error) {
      dispatch({ type: CREATE_LANDING_PAGE_FAILURE, payload: error.message });
    }
  };

  export const getLanding = () => async (dispatch) => {
    try {
      dispatch({ type: GET_LANDING_PAGE_REQUEST });
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem("token"),
        },
      };
      const { Data } = await axios.get(
        `${siteConfig.base_URL}/api/v1/DoorPlusBG`,

        config
      );
      dispatch({ type: GET_LANDING_PAGE_SUCCESS, payload: Data });
    } catch (error) {
      dispatch({ type: GET_LANDING_PAGE_FAILURE, payload: error.message });
    }
  };