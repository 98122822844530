import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    IconButton,
    Grid,
    Tabs,
    Tab,
} from '@mui/material';
import { ArrowBack, CleaningServices, Build, Restaurant, Home, ElectricalServices, Plumbing, Spa } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const services = [
    { icon: <CleaningServices />, title: 'Home Cleaning', description: 'Professional home cleaning services.' },
    { icon: <Build />, title: 'Home Repairs', description: 'Expert repair services for your home.' },
    { icon: <Restaurant />, title: 'Catering', description: 'Delicious catering services for all occasions.' },
    { icon: <Home />, title: 'Furniture Assembly', description: 'Efficient furniture assembly services.' },
    { icon: <ElectricalServices />, title: 'Electrical Services', description: 'Professional electrical services for your home.' },
    { icon: <Plumbing />, title: 'Plumbing Services', description: 'Expert plumbing services for your home.' },
    { icon: <Spa />, title: 'Spa Services', description: 'Relax and rejuvenate with our spa services.' },
];

const AboutUs = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <React.Fragment>
   <Box sx={{ flexGrow: 1, bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                bgcolor: 'white',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                position: 'sticky',
                top: 0,
                zIndex: 10
            }}>
                <IconButton component={Link} to="/ProfilePage" edge="start" sx={{ mr: 1 ,color:'black' , }}>
                    <ArrowBack style={{fontSize:'1rem', fontWeight: 500,}}/>
                </IconButton>
                <Typography sx={{ fontSize:'1rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                    About Us
                </Typography>
            </Box>

            <Container maxWidth="md" sx={{ py: 6 ,mb:5, flex: 1 }}>
                <Box sx={{
                    mb: 4, textAlign: 'center', py: 4,
                    textAlign: 'center', height: '100%',
                    borderRadius: 5,
                    background: 'white',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '25px 25px 75px #d9d9d9, -25px -25px 75px #ffffff',
                    },
                }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mb: 2, fontSize: '22px' }}>
                        Transforming Homes with Excellence
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: '80%', mx: 'auto', mb: 3, fontSize: '0.7rem' }}>
                        We're dedicated to providing top-notch home services, ensuring a seamless and hassle-free experience for all your home needs. From cleaning and repairs to specialized services, we bring expertise and excellence to your doorstep.
                    </Typography>
                </Box>

                <Tabs 
                    value={tabValue} 
                    onChange={handleTabChange} 
                    centered 
                    sx={{ mb: 4, '& .MuiTabs-indicator': { backgroundColor: 'black' } }}
                >
                    <Tab 
                        label="Our Services" 
                        sx={{ color: 'black', '&.Mui-selected': { color: 'black' } }} 
                    />
                </Tabs>

                <Box hidden={tabValue !== 0}>
                    <Grid container spacing={4}>
                        {services.map((service, index) => (
                            <Grid item xs={12} sm={4} key={index} sx={{ mb: 2 }}>
                                <Box
                                    sx={{
                                        textAlign: 'center', height: '100%',
                                        padding: '14px',
                                        borderRadius: 5,
                                        background: 'white',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-8px)',
                                            boxShadow: '25px 25px 75px #d9d9d9, -25px -25px 75px #ffffff',
                                        },
                                    }}
                                >
                                    <Typography style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    }}>
                                        {service.icon}
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, fontSize: '1rem' }}>
                                        {service.title}
                                    </Typography>
                                    <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                                        {service.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
        <MobileBottomNavigation/>
        </React.Fragment>
     
    );
};

export default AboutUs;
