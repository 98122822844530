import React, { useState } from "react";
import {
    Button,
    Card,
    Grid,
    InputLabel,
    Stack,
    TextField,
} from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { FaqCreate } from "../../action/FaqActions";

const FaqCreateForm = () => {
    const [faq, setFaq] = useState({
        Questions: "",
        Answers: "",
    });

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFaq((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateInput = () => {
        const { Questions, Answers } = faq;
        if (!Questions.trim() || !Answers.trim()) {
            toast.error("Please fill all required fields");
            return false;
        }
        if (Questions.length < 10 || Questions.length > 200) {
            toast.error("Questions must be between 10 and 200 characters");
            return false;
        }
        if (Answers.length < 20 || Answers.length > 1000) {
            toast.error("Answers must be between 20 and 1000 characters");
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateInput()) {
            dispatch(FaqCreate(faq));
            setFaq({
                Questions: "",
                Answers: "",
            });
            toast.success("FAQ created successfully!");
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container style={{ marginTop: "20px" }}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card
                            variant="outlined"
                            style={{ padding: "30px", margin: "15px", borderRadius: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px" }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="faq-questions"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Faq Questions <span style={{ color: "#422afb" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Faq Questions"
                                            id="faq-questions"
                                            name="Questions"
                                            value={faq.Questions}
                                            variant="outlined"
                                            onChange={handleChange}
                                            InputProps={{
                                                style: { borderColor: "#c4c4c4" },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                           
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <InputLabel
                                            htmlFor="faq-answers"
                                            style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                        >
                                            Faq Answers <span style={{ color: "#422afb" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            size="small"
                                            label="Faq Answers"
                                            id="faq-answers"
                                            name="Answers"
                                            variant="outlined"
                                            value={faq.Answers}
                                            onChange={handleChange}
                                            InputProps={{
                                                style: { borderColor: "#c4c4c4" },
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "12px" },
                                            }}
                                         
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{display:'flex', justifyContent:'end'}}>
                                    <Button type="submit" variant="contained" color="primary" style={{ fontSize:'12px', background: "#422afb", padding: "12px 24px", borderRadius: "5px" }}>
                                        Submit
                                    </Button>
                                    </div>
                                   
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </form>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default FaqCreateForm;
