import React from 'react';
import {
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
    Paper,
    styled,
    Avatar
} from '@mui/material';
import {
    ArrowBack,
    ChevronRight,
    History,
    AutoAwesome,
    Help,
    Info
} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';

const StyledPaper = styled(Paper)(({ theme }) => ({
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    border: 'none',
    borderRadius: 16,
    marginBottom: theme.spacing(2),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
    },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginRight: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: 8,
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.03)',
    },
}));

const menudata = [
    { text: 'My Profile', icon: <AccountCircleOutlinedIcon fontSize="small" />, link: '/EditProfile' },
    { text: 'Manage Address', icon: <AutoAwesome fontSize="small" />, link: '/Address' },
    { text: 'Booking History', icon: <History fontSize="small" />, link: '/BookingHistory' },
    { text: 'Need help?', icon: <Help fontSize="small" />, link: '/NeedHelp' },
    { text: 'About Us', icon: <Info fontSize="small" />, link: '/AboutUs' },
];

const ProfilePage = () => (
    <Box sx={{
        width: '100%',
        maxWidth: '100vw', margin: 'auto', bgcolor: '#f5f5f5', minHeight: '100vh'
    }}>
        <Box sx={{
            display: 'flex', alignItems: 'center', p: 2, justifyContent: 'center',
            background: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'sticky', top: 0, zIndex: 10
        }}>
            <IconButton size="small" component={Link} to="/" sx={{ position: 'absolute', left: 16 }}>
                <ArrowBack fontSize="small" style={{ color: 'black' }} />
            </IconButton>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", fontWeight: 500 }}>
                My Accounts
            </Typography>
        </Box>

        <Box sx={{ p: 2, mt: 4 }}>
            <StyledPaper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <Avatar sx={{ width: 56, height: 56, bgcolor: 'black', mr: 2 }}>GU</Avatar>
                </Box>
                <Typography variant="body2" sx={{ color: 'black', textAlign: "left", padding: "2px" }}>
                    Login to access your profile
                </Typography>
                <Button variant="outlined" sx={{ borderColor: 'black', color: 'black', borderRadius: 20, textTransform: 'none', px: 3 }}>
                    Login
                </Button>
            </StyledPaper>

            <StyledPaper sx={{ mt: 2 }}>
                <List disablePadding>
                    {menudata.map((item, index) => (
                        <React.Fragment key={item.text}>
                            <StyledListItem button component={Link} to={item.link}>
                                <IconWrapper sx={{ minWidth: 40 }}>{item.icon}</IconWrapper>
                                <ListItemText primary={item.text} primaryTypographyProps={{ variant: 'body2' }} />
                                <ChevronRight color="action" fontSize="small" />
                            </StyledListItem>
                            {index < menudata.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </StyledPaper>

            <StyledPaper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ bgcolor: '#e3f2fd', borderRadius: '50%', width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#1976d2' }}>CU</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{ fontWeight: 'medium' }}>Contact Us</Typography>
                            <Typography variant="caption" color="text.secondary"></Typography>
                        </Box>
                    </Box>
                    <ChevronRight color="action" fontSize="small" />
                </Box>
            </StyledPaper>

            <StyledPaper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <Typography variant="body2" sx={{ fontWeight: 'medium', color: 'red' }}>Log Out</Typography>
                        </Box>
                    </Box>
                </Box>
            </StyledPaper>
        </Box>

        <Box sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'black' }}>
                Door Plus
            </Typography>
            <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 0.5 }}>
                Made with ♥ in India
            </Typography>
        </Box>
    </Box>
);

export default ProfilePage;
