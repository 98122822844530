import React, { useRef, useState } from "react";
import {
    Button,
    Card,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadimg from "../../Admin/icon/image.png";
import { blogCreate } from "../../action/blogAction";
import JoditEditor from "jodit-react";

const CreateBlog = () => {
    const [Blog, setBlog] = useState({
        Blogtitle: "",
        description: "",
        file: null
    });
    const dispatch = useDispatch();
    const editor = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlog((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEditorChange = (content) => {
        setBlog((prevState) => ({
            ...prevState,
            description: content,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!Blog.Blogtitle || !Blog.description) {
            toast.error("Please fill all required fields");
            return;
        }
        dispatch(blogCreate(Blog));
        setBlog({
            Blogtitle: "",
            description: "",
            file: null,
        });
    };

    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBlog((prevState) => ({
                ...prevState,
                file: file,
            }));
        }
    };

    const handleRemoveImage = () => {
        setBlog((prevState) => ({
            ...prevState,
            file: null,
        }));
    };

    return (
        <>
            <Grid container style={{ marginTop: "20px" }} component="form" onSubmit={handleSubmit}>
                <Grid item xs={12} sm={12} md={6}>
                    <Card
                        variant="outlined"
                        style={{ padding: "30px", margin: "15px", borderRadius: "20px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px" }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ marginBottom: "5px" }}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="BlogBlogtitle"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Blog title<span style={{ color: "#422afb" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                    
                                        fullWidth
                                        size="small"
                                         placeholder="blog title"
                                         
                                        id="BlogBlogtitle"
                                        name="Blogtitle"
                                        variant="outlined"
                                        value={Blog.Blogtitle}
                                        onChange={handleChange}
                                        InputProps={{
                                            style: { borderColor: "#422afb", 
                                                    padding:"10px 0px",
                                                    fontSize:'12px'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { fontSize: "12px" },
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel
                                        htmlFor="description"
                                        style={{ marginBottom: "5px", fontSize: "12px", color: "#422afb" }}
                                    >
                                        Description<span style={{ color: "#f44336" }}>*</span>
                                    </InputLabel>
                                    <JoditEditor
                                        ref={editor}
                                        value={Blog.description}
                                        tabIndex={1}
                                        onBlur={handleEditorChange}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Card
                        variant="outlined"
                        style={{
                            borderRadius: "20px",
                            padding: "4px",
                            margin: "14px",
                            height:'390px' ,   
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px"

                        }}
                    >
                        {Blog.file ? (
                            <>
                                <img src={URL.createObjectURL(Blog.file)} alt="Uploaded" style={{ height: "160px", marginBottom: "10px" }} />
                                <Button onClick={handleRemoveImage} variant="outlined" color="secondary">
                                    Remove Image
                                </Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" style={{ marginBottom: "2px", color: "#422afb", fontSize: "12px" }}>
                                    Upload Image
                                </Typography>
                                <Card
                                    variant="outlined"
                                    style={{
                                        padding: "20px",
                                        margin: "10px",
                                        height: "160px",
                                        border: "2px #422afb dashed",
                                        width: "200px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        onChange={handleFileChange}
                                    />
                                    <IconButton onClick={handleUploadButtonClick} color="primary">
                                        <img
                                            style={{
                                                height: "100px"
                                            }}
                                            src={uploadimg} alt="upload img" />
                                    </IconButton>
                                </Card>
                            </>
                        )}
                    </Card>
                   
                </Grid>
                <Grid item lg={12} sm={12} xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                        <Button type="submit" variant="contained" color="primary" style={{
                            background: "#422afb", padding: "12px 24px", borderRadius: "10px", fontSize:'12px'
                        }} disabled={!Blog.Blogtitle || !Blog.description || !Blog.file}>
                            Submit
                        </Button>
                    </Grid>
            </Grid>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default CreateBlog;
