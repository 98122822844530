import React from 'react';
import {
    Container,
    Typography,
    CardContent,
    Box,
    IconButton,
    Grid,
    Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const StyledBox = styled(Box)(({ theme }) => ({
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
    border: 'none',
    borderRadius: 16,
    background: 'white',
    marginBottom: theme.spacing(2),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-2px)',
    },
}));

const StatusChip = styled(Chip)(({ status }) => ({
    borderRadius: 8,
    fontSize: '0.7rem',
    height: 24,
    color: status === 'Completed' ? '#1B5E20' :
        status === 'Pending' ? '#E65100' : '#B71C1C',
    backgroundColor: status === 'Completed' ? '#C8E6C9' :
        status === 'Pending' ? '#FFE0B2' : '#FFCDD2',
}));

const bookings = [
    { id: 2, service: 'Home Cleaning', date: '20 Jun 2024', time: '02:30 PM', status: 'Pending', price: '800', duration: '3 hours', provider: 'CleanHome Services' },
    { id: 3, service: 'Plumbing', date: '25 Jun 2024', time: '11:45 AM', status: 'Cancelled', price: '1120', duration: '2 hours', provider: 'FixIt Plumbers' },
];

const ServiceBookingHistory = () => {
    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'white',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10
                }}>
                    <IconButton component={Link} to="/" edge="start" sx={{ mr: 1, color: 'black', }}>
                        <ArrowBack style={{ fontSize: '1rem', fontWeight: 500, }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        My Bookings
                    </Typography>
                </Box>
                <Container maxWidth="lg" sx={{ flexGrow: 1, py: 3, maxWidth: '800px !important' }}>
                    <Grid container spacing={1}>
                        {bookings.map((booking) => (
                            <Grid item xs={12} sm={6} key={booking.id}>
                                <StyledBox>
                                    <CardContent sx={{ p: 2.5 }}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                            <Box>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                                    {booking.service}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    {booking.provider}
                                                </Typography>
                                            </Box>
                                            <StatusChip label={booking.status} status={booking.status} />
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" mt={1}>
                                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                                {booking.date}, {booking.time}
                                            </Typography>
                                            <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                                {booking.duration}
                                            </Typography>
                                        </Box>
                                        <Box mt={1} pt={1} borderTop="1px solid #E0E0E0" display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                Total Price
                                            </Typography>
                                            <Typography variant="subtitle2" fontWeight={600} sx={{ fontSize: '0.8rem' }}>
                                                ₹{booking.price}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </StyledBox>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <MobileBottomNavigation />
        </React.Fragment>

    );
};

export default ServiceBookingHistory;