
export const CREATE_LANDING_PAGE_REQUEST = 'CREATE_LANDING_PAGE_REQUEST';
export const CREATE_LANDING_PAGE_SUCCESS = 'CREATE_LANDING_PAGE_SUCCESS';
export const CREATE_LANDING_PAGE_FAILURE = 'CREATE_LANDING_PAGE_FAILURE';

export const GET_LANDING_PAGE_REQUEST = 'GET_LANDING_PAGE_REQUEST';
export const GET_LANDING_PAGE_SUCCESS = 'GET_LANDING_PAGE_SUCCESS';
export const GET_LANDING_PAGE_FAILURE = 'GET_LANDING_PAGE_FAILURE';

export const UPDATE_LANDING_PAGE_REQUEST = 'UPDATE_LANDING_PAGE_REQUEST';
export const UPDATE_LANDING_PAGE_SUCCESS = 'UPDATE_LANDING_PAGE_SUCCESS';
export const UPDATE_LANDING_PAGE_FAILURE = 'UPDATE_LANDING_PAGE_FAILURE';

export const DELETE_LANDING_PAGE_REQUEST = 'DELETE_LANDING_PAGE_REQUEST';
export const DELETE_LANDING_PAGE_SUCCESS = 'DELETE_LANDING_PAGE_SUCCESS';
export const DELETE_LANDING_PAGE_FAILURE = 'DELETE_LANDING_PAGE_FAILURE';


export const RESET_LANDING_PAGE_STATUS = 'RESET_LANDING_PAGE_STATUS';
