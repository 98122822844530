import React, { useState } from 'react';
import { Box, Typography, IconButton, Modal, TextField, Button, Backdrop } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ArrowBack } from '@mui/icons-material';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const inputStyle = {
    bgcolor: 'white',
    '&:hover': { bgcolor: 'white' },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#BDBDBD',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000000',
    },
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
};

function AddressesUI() {
    const [open, setOpen] = useState(false);

    const addresses = [
        { name: 'Kanpur', icon: <HomeIcon sx={{ color: '#3B82F6' }} />, address: 'W3-092, 9th Floor, Kanpur, Near DLF Phase 5 Club, Opposite...' },
        { name: 'Kanpur', icon: <HomeIcon sx={{ color: '#3B82F6' }} />, address: 'W3-092, 9th Floor, Kanpur, Near DLF Phase 5 Club, Opposite...' },

    ];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'white',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10
                }}>
                    <IconButton component={Link} to="/ProfilePage" edge="start" sx={{ mr: 1, color: 'black', }}>
                        <ArrowBack style={{ fontSize: '1rem', fontWeight: 500, }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        My Address
                    </Typography>
                </Box>

                <Box sx={{ px: 2, mb: 2, mt: 3 }}>
                    <Typography
                        onClick={handleOpen}
                        sx={{
                            fontSize: '15px',
                            bgcolor: '#e0e0e0',
                            color: 'black',
                            textTransform: 'none',
                            justifyContent: 'flex-start',
                            pl: 2,
                            textAlign: 'center',
                            py: 2.5,
                            fontWeight: 500,
                            '&:hover': { bgcolor: 'white' },
                            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                            borderRadius: '8px',
                            cursor: 'pointer'
                        }}
                    >
                        Add Address
                    </Typography>
                </Box>

                {addresses.map((item, index) => (
                    <Box key={index} sx={{ bgcolor: 'white', mx: 2, mb: 1, p: 3, borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.05)', border: 'none', transition: 'transform 0.2s ease-in-out', '&:hover': { transform: 'translateY(-2px)' } }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                minWidth: 40,
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            }}>{item.icon}</Box>
                            <Box sx={{ flexGrow: 1, ml: 2, minWidth: 0 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 500, fontSize: '15px', color: 'black' }}>{item.name}</Typography>
                                <Typography variant="body2" sx={{ fontSize: '0.75rem', color: '#6B7280', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}>{item.address}</Typography>
                            </Box>
                            <IconButton edge="end" sx={{ color: '#9CA3AF' }}><MoreVertIcon /></IconButton>
                        </Box>
                    </Box>
                ))}

                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                        style: { backdropFilter: 'blur(4px)' },
                    }}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Box
                        sx={{
                            bgcolor: 'white',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            p: 3,
                            minWidth: 280,
                            maxWidth: 400,
                            width: '82%',
                            borderRadius: 3,
                            zIndex: 1301
                        }}
                    >
                        <Typography variant="h6" component="h2" sx={{ mb: 3, fontWeight: 600, color: '#1F2937', textAlign: 'center' }}>
                            Add New Address
                        </Typography>
                        <form>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Name</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Address Line 1</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Address Line 2</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>City</Typography>
                                    <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>State</Typography>
                                    <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                                </Box>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Pincode</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 3 }}>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                    sx={{
                                        flex: 1,
                                        color: '#000000',
                                        borderColor: '#000000',
                                        '&:hover': { bgcolor: '#F5F5F5' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        py: 1.5,
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        flex: 1,
                                        bgcolor: '#000000',
                                        '&:hover': { bgcolor: '#333333' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        py: 1.5,
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Modal>
            </Box>
            <MobileBottomNavigation />
        </React.Fragment>

    );
}

export default AddressesUI;
