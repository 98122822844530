import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AuthReducer from "./reducer/UserAuthReducer";
import { categoryReducer, deleteCategoryReducer, getCategoryReducer, updateCategoryReducer } from "./reducer/categoryReducer";
import { SubcategoryReducer, deleteSubCategoryReducer, fetchSubCategoryReducer, updateSubCategoryReducer } from "./reducer/subCategoryReducer";
import { BlogReducer, DeletedBlogsReducer, getBlogsReducer } from "./reducer/blogReducer";
import { DeletedFaqReducer, FaqReducer, getFaqReducer } from "./reducer/faqReducer";
import { CreateLanding } from "./action/landingBgAction";
import { GetLandingReducer } from "./reducer/LandingbgReducer";
import { BookingReducer, getBookingReducer, updateBookingReducer } from "./reducer/BookingReducer";

const Reducer = combineReducers({
  user: AuthReducer,
  category: categoryReducer,
  getAllCategory: getCategoryReducer,
  DeletedCategory: deleteCategoryReducer,
  updateCategory: updateCategoryReducer,
  subCategory: SubcategoryReducer,
  fetchAllSubCategory: fetchSubCategoryReducer,
  DeletedSubCategory: deleteSubCategoryReducer,
  updateSubCategory: updateSubCategoryReducer,
  Blogs: BlogReducer,
  getBlogs:getBlogsReducer,
  DeletedBlogs:DeletedBlogsReducer,
  Faq: FaqReducer,
  getFaq: getFaqReducer,
  DeletedFaq:DeletedFaqReducer,
  landingBg: CreateLanding,
  getlanding: GetLandingReducer,
  Booking:BookingReducer,
  getBooking:getBookingReducer,
  updateBooking:updateBookingReducer
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  Reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
