import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./allcatstyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../../action/catgoryAction";
import { ArrowForward } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import img from "../../../../assets/mensSaloon.jpg"


const AllCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAllCategory } = useSelector((state) => state.getAllCategory);
  const [CategoryateData, setCategoryateData] = useState(() => getAllCategory);

  console.log(getAllCategory, "hjhjhjhj");
  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    if (getAllCategory) {
      setCategoryateData(getAllCategory);
    }
  }, [getAllCategory]);

  // const colors = [
  //   "rgb(255, 231, 217)",
  //   "rgb(217, 255, 231)",
  //   "rgb(231, 217, 255)",
  //   "rgb(255, 217, 231)",
  //   "rgb(217, 231, 255)",
  // ];
  const navigateCategory = (obj) => {
    console.log(obj, "ggg");
    navigate(`/category/${obj._id}`, { state: { obj } });
  };


  const data = [
    {
      id:1,
      img:img,
      title:'test'
    },  {
      id:1,
      img:img,
      title:'test'
    },
  ]

  return (
    <Box style={{ marginTop: "8%" }} className="doorplusAllcatmt">
      <Box style={{ padding: "30px" }} className="mediaAllcatBoxbpd">
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: "9px" }}
            >
              <Grid item xs={6}>
                <Typography style={AllCategoryStyle.heading} className="mediaAllHead">
                  All Category
                </Typography>
                <Typography style={AllCategoryStyle.ChlidParagraph} className="allcatsubTitle">
                  Always Be ready to choose best service
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Typography style={AllCategoryStyle.viewAllStyle} className="mobilemediaview">
                  <span
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      padding: "14px",
                      cursor: "pointer",
                    }}
                  >
                    view all <ArrowForward style={{ fontSize: "13px" }} className="doorplusviewicon" />
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {CategoryateData?.categories?.length > 0 ? (
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                infinite={true}
              >
                {CategoryateData.categories.map((category) => (
                 <div>
                       <Box
                    key={category._id}
                    style={AllCategoryStyle.ImageContainer}
                    onClick={() => navigateCategory(category)}
                  >
                    <div style={AllCategoryStyle.ZoomContainer}>
                      <img
                      
                        src={category.image?.url}
                        alt={category.title}
                        style={AllCategoryStyle.CardStyle}
                        className="doorplus-imagebox"
                      />
                    </div>
                    <div style={AllCategoryStyle.CardFistTextStyle} className="exploreBtn">
                      Explore now
                    </div>
                   
                  </Box>
                   <Typography style={AllCategoryStyle.CategoryTitle} className="doorplus-mobiletitle">
                      {category.title}
                    </Typography>
                 </div>
             
                ))}
              </Carousel>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AllCategory;

const AllCategoryStyle = {
  heading: {
    fontSize: "32px",
    fontWeight: 700,
    color: "black",
    textTransform: "capitalize",
    marginBottom: "4px",
  },
  CategoryTitle: {
    textAlign: "justify",
    marginTop: "2px",
    paddingLeft:"5px",
    fontSize: "15px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
  viewAllStyle: {
    fontSize: "15px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
  CardStyle: {
    borderRadius: "5px",
    height: "200px",
    cursor: "pointer",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    objectFit: "cover",
  },
  ImageContainer: {
    position: "relative",
    margin: "5px",
    overflow: "hidden",
  },
  CardFistTextStyle: {
    position: "absolute",
    bottom: 14,
    left: 8,
    fontSize: "16px",
    fontWeight: "500",
    color: "white",
    textTransform: "capitalize",
    padding: "10px",
    backgroundColor: "rgb(0 0 0 / 49%)",
    borderRadius: "5px",
    cursor:'pointer'
  },
  CardlastTextStyle: {
    position: "absolute",
    left: 10,
    bottom: 19,
    fontSize: "17px",
    fontWeight: "300",
    color: "white",
    textTransform: "capitalize",
    padding: "10px",
  },
  ChlidParagraph: {
    fontSize: "13px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
