import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Button,
  TextField,
} from '@mui/material';
import { ArrowBack, QuestionAnswer, Phone, Email } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MobileBottomNavigation from '../mobilebottomnav/bottomnav';

const NeedHelp = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, bgcolor: '#f5f5f5', minHeight: '100vh', pb: 6 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          bgcolor: 'white',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          position: 'sticky',
          top: 0,
          zIndex: 10
        }}>
          <IconButton component={Link} to="/" edge="start" sx={{ mr: 1, color: 'black' }}>
            <ArrowBack style={{ fontSize: '1rem', fontWeight: 500 }} />
          </IconButton>
          <Typography sx={{ fontSize: '1rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
            Need Help
          </Typography>
        </Box>

        <Container maxWidth="md" sx={{ py: 6, mb: 6 }}>
          <Box sx={{
            mb: 4, textAlign: 'center', py: 4,
            height: '100%',
            borderRadius: 5,
            background: 'white',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-8px)',
              boxShadow: '25px 25px 75px #d9d9d9, -25px -25px 75px #ffffff',
            },
          }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mb: 2, fontSize: '22px' }}>
              We're Here to Help You
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: '80%', mx: 'auto', mb: 3, fontSize: '0.7rem' }}>
              Our support team is always ready to assist you. Choose the best way to get help below.
            </Typography>
          </Box>
          <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 4 }}>
            <Tab label="Help Options" />
            <Tab label="Contact Us" />
          </Tabs>
          <Box hidden={tabValue !== 0}>
            <Grid container spacing={4}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={4} key={index} sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      textAlign: 'center', height: '100%',
                      padding: '15px',
                      borderRadius: 5,
                      background: 'white',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '25px 25px 75px #d9d9d9, -25px -25px 75px #ffffff',
                      },
                    }}
                  >
                    <Typography style={{
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    }}>
                      {service.icon}
                    </Typography>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 500, fontSize: '1rem' }}>
                      {service.title}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                      {service.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box hidden={tabValue !== 1}>
            <Box
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                p: 3,
                minWidth: 280,
                maxWidth: 400,
                width: '82%',
                borderRadius: 3,
                zIndex: 1301,
                margin: 'auto',
              }}
            >
              <form>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Enter Full Name</Typography>
                  <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Enter Service Name</Typography>
                  <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    size="small"
                    InputProps={{ sx: inputStyle }}
                  />
                </Box>
                <Box sx={{ display: 'flex', mt: 3 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      flex: 1,
                      bgcolor: '#000000',
                      '&:hover': { bgcolor: '#333333' },
                      borderRadius: '8px',
                      textTransform: 'none',
                      py: 1.5,
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </Box>
      <MobileBottomNavigation />
    </React.Fragment>
  );
};

export default NeedHelp;

const services = [
  { icon: <QuestionAnswer />, title: 'FAQ', description: 'Find answers to common questions' },
  { icon: <QuestionAnswer />, title: 'Live Support', description: 'Find quick answers' },
  { icon: <Email />, title: 'Email Support', description: 'Send us an email' },
  { icon: <Phone />, title: 'Call Us', description: 'Speak with an agent' },
];

const inputStyle = {
  bgcolor: 'white',
  '&:hover': { bgcolor: 'white' },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E0E0E0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BDBDBD',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000000',
  },
  borderRadius: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
};
