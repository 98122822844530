import React, { useEffect, useState } from 'react';
import { Button, Card, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import './StepForm.css';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeIcon from '@mui/icons-material/Home';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSubCetegory } from '../../../../action/subCategory';
import { FaHome } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa";
import { BookingCreate } from '../../../../action/BookingAction';

const BookingCheckOut = () => {
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [lineBgColor, setLineBgColor] = useState('#ccc');
  const { getAllCategory } = useSelector((state) => state.getAllCategory);
  const [subcategory, setSubcategory] = useState('');
  const { fetchAllSubCategory } = useSelector((state) => state.fetchAllSubCategory);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [timeRanges, setTimeRanges] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();


  const [personalDetails, setPersonalDetails] = useState({
    name: '',
    lastname: '',
    email: '',
    mobile: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    alert("hhh")
    e.preventDefault();
    console.log(completedSteps,"fff")
    if(completedSteps.length===0){
      dispatch(BookingCreate(personalDetails));
      setPersonalDetails({
        name: '',
        lastname: '',
        email: '',
        mobile: ''
      });
      nextStep();
    }else if(completedSteps.length==1){
      console.log(completedSteps,"fffhghghghg")
      nextStep();
    }
   
   
  };

  
  useEffect(() => {
    if (id) {
      const category = getAllCategory?.categories?.find(item => item._id === id);
      if (category) {
        setSelectedCategory(category);
      }
    }
  }, [getAllCategory]);
  useEffect(() => {
    dispatch(fetchSubCetegory(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (fetchAllSubCategory) {
      setSubcategory(fetchAllSubCategory);
    }
  }, [fetchAllSubCategory]);
  useEffect(() => {
    const generateTimeRanges = (date) => {
      const ranges = [];
      const now = new Date();
      const isToday = new Date(date).toDateString() === now.toDateString();
      let startHour;
      let startMinutes;

      if (isToday) {
        startHour = now.getMinutes() < 30 ? now.getHours() : now.getHours() + 1;
        startMinutes = now.getMinutes() < 30 ? 30 : 0;
      } else {
        startHour = 10;
        startMinutes = 0;
      }
      const maxHour = 19;
      if (startHour >= maxHour) {
        return ranges;
      }
      while (startHour < maxHour) {
        let endHour = startHour + 2;
        if (endHour > maxHour) endHour = maxHour;

        const start = `${startHour.toString().padStart(2, '0')}:${startMinutes === 0 ? '00' : '30'} ${startHour < 12 ? 'AM' : 'PM'}`;
        const end = `${endHour.toString().padStart(2, '0')}:00 ${endHour < 12 ? 'AM' : 'PM'}`;
        ranges.push({ start, end });
        startHour += 2;
        startMinutes = 0;
      }
      return ranges;
    };
    setTimeRanges(generateTimeRanges(selectedDate));
  }, [selectedDate]);
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const nextStep = () => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    setStep(step + 1);
    setLineBgColor('#007bff');
  };
  const prevStep = () => {
    if (completedSteps.includes(step - 1)) {
      setCompletedSteps(completedSteps.filter(s => s !== step - 1));
    }
    setStep(step - 1);
    setLineBgColor('#ccc');
  };
  const ProgressBar = () => {
    const icons = [<PersonIcon />, <HomeIcon />, <CalendarMonthIcon />, <CreditCardIcon />];
    return (
      <div className="progress-bar">
        {icons.map((icon, index) => (
          <React.Fragment key={index}>
            <div className={`icon ${completedSteps.includes(index + 1) ? 'completed' : ''}`}>
              {completedSteps.includes(index + 1) ? <CheckIcon /> : icon}
            </div>
            {index !== icons.length - 1 && (
              <div
                className="line"
                style={{ backgroundColor: index + 1 === step ? lineBgColor : '#ccc' }}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };
  const StepOne = () => (
    <div className="step" style={{ textAlign: 'center' }}>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px' }}>
          <PersonIcon style={{ fontSize: 30 }} />
          <Typography style={{ fontSize: '25px' }}>
            Let's start with the personal details
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="name"
              value={personalDetails.name}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastname"
              value={personalDetails.lastname}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={personalDetails.email}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mobile No"
              variant="outlined"
              fullWidth
              name="mobile"
              value={personalDetails.mobile}
              onChange={handleChange}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
          <Button
            type="submit"
            variant="contained"
            style={{
              padding: '14px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: 'black'
            }}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
  const StepTwo = () => (
    <div className="step" style={{ textAlign: 'center' }}>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px' }}>
          <HomeIcon style={{ fontSize: 30 }} />
          <Typography style={{ fontSize: '25px' }}>
            Address
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Flat House No"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Land Mark"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="city"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="zip code"
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'inherit',
                },
                '& .MuiInputLabel-root.MuiFormLabel-filled': {
                  color: 'inherit',
                },
              }}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "30px" }}>
          <Button
            onClick={prevStep}
            variant="contained"
            style={{
              padding: '14px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: "black"
            }}
          >
            Previous
          </Button>
          <Button
           type="submit"
           variant="contained"
           style={{
             padding: '14px 30px',
             fontSize: '11px',
             borderRadius: '1px',
             color: '#fff',
             background: 'black'
           }}
           
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
  const StepThree = () => (
    <div className="step">
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px', textAlign: "center" }}>
          <CalendarMonthIcon style={{ fontSize: 30 }} />
          <Typography style={{ fontSize: '25px', marginLeft: '10px' }}>
            Appointment
          </Typography>
        </div>
        <Grid container spacing={2}>
          {/* <Grid item xs={6}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category"
                  value={selectedCategory}
                //  onChange={handleChange}

                >
                  <MenuItem value="">
                    Select Subcategory
                  </MenuItem>
                  {
                    getAllCategory?.categories?.map((item) => (
                      <MenuItem value={item._id}
                        key={item._id}>{item.title}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              value={selectedCategory.title}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'none',
                  },
                },

              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              value={selectedCategory.title}
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="subcategory-select-label">SubCategory</InputLabel>
              <Select
                labelId="subcategory-select-label"
                id="subcategory-select"
                label="SubCategory"
              >
                <MenuItem value="">
                  Select Subcategory
                </MenuItem>
                {subcategory?.getsubcategory?.map((item) => (
                  <MenuItem value={item._id}
                    key={item._id}>{item.SubcategoryTitle}</MenuItem>
                ))
                }

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              type="date"
              margin="normal"
              value={selectedDate}
              onChange={handleDateChange}
              inputProps={{
                min: new Date().toISOString().split('T')[0]
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'none',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel id="Time-select-label">Time</InputLabel>
              <Select
                labelId="Time-select-label"
                id="Time"
                value={selectedTime}
                onChange={handleTimeChange}
                label="Time"
              >
                {timeRanges.map((range, index) => (
                  <MenuItem key={index} value={`${range.start} - ${range.end}`}>
                    {`${range.start} - ${range.end}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Button
            onClick={prevStep}
            variant="contained"
            style={{
              padding: '14px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: 'black'
            }}
          >
            Previous
          </Button>
          <Button
            onClick={nextStep}
            variant="contained"
            style={{
              padding: '10px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: 'black'
            }}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
  const StepFour = () => (
    <div className="step" style={{ textAlign: 'center' }}>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px' }}>
          <CreditCardIcon style={{ fontSize: 30 }} />
          <Typography style={{ fontSize: '25px' }}>
            Choose Your Payment Methode
          </Typography>
        </div>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RadioGroup style={{ display: "block", margin: "0 auto", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", textAlign: "center" }}>
          <FormControlLabel
            value="razorpay"
            control={
              <Radio
                icon={<CreditCardIcon style={{ fontSize: "70px", backgroundColor: "#FADBD8", borderRadius: "50%", padding: "10px" }} />}
                checkedIcon={<CreditCardIcon style={{ fontSize: "70px", backgroundColor: "#F1948A", borderRadius: "50%", padding: "10px" }} />}
              />
            }
          />
        </RadioGroup>
        <Typography style={{ fontSize: '14px', textAlign: "center", marginTop: "10px" }}>
          Credit Card/Debit Card/NetBanking (Razorpay)
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <RadioGroup style={{ display: "block", margin: "0 auto", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "20px", textAlign: "center" }}>
          <FormControlLabel
            value="postService"
            control={
              <Radio
                icon={<FaHome style={{ fontSize: "70px", backgroundColor: "#D6EAF8", borderRadius: "50%", padding: "10px" }} />}
                checkedIcon={<FaHome style={{ fontSize: "70px", backgroundColor: "#85C1E9", borderRadius: "50%", padding: "10px" }} />}
              />
            }
          />
        </RadioGroup>
        <Typography style={{ fontSize: '14px', textAlign: "center", marginTop: "10px" }}>
          Payment Post Service (C O D)
        </Typography>
      </Grid>
    </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
          <Button
            onClick={prevStep}
            variant="contained"
            style={{
              padding: '14px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: "black"
            }}
          >
            Previous
          </Button>
          <Button
            onClick={nextStep}
            variant="contained"
            style={{
              padding: '10px 30px',
              fontSize: '11px',
              borderRadius: '1px',
              color: '#fff',
              background: "black"
            }}
          >
         Submit
          </Button>
        </div>
      </form>
    </div>
  );
  return (
    <Grid container spacing={2}
      style={{
        marginTop: "50px",
        marginBottom: "40px",

      }}>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <Card elevation={0} style={{
          padding: "40px",
          borderRadius: "20px",
          boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px"
        }

        }>
          <div className="step-form">
            <ProgressBar />
            {step === 1 && <StepOne />}
            {step === 2 && <StepTwo />}
            {step === 3 && <StepThree />}
            {step === 4 && <StepFour />}

          </div>
        </Card>

      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
export default BookingCheckOut;
