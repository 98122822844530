import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { Link } from "react-router-dom";

function MobileBottomNavigation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const shouldShowNavigation = isMobile;
  const [selectedIcon, setSelectedIcon] = useState("Home");

  if (!shouldShowNavigation) {
    return null;
  }

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  return (
    <BottomNavigation
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
        zIndex: "1000",
      }}
    >
      <BottomNavigationAction
        label="Home"
        component={Link}
        to="/"
        icon={<IoHomeOutline style={{ color: selectedIcon === "Home" ? "grey" : "black", fontSize: '22px' }} />}
        style={{ color: selectedIcon === "Home" ? "grey" : "black" }}
        onClick={() => handleIconClick("Home")}
      />
      <BottomNavigationAction
        label="My Bookings"
        component={Link}
        to="/BookingHistory"
        icon={<BsStars style={{ color: selectedIcon === "offers" ? "grey" : "black", fontSize: '22px' }} />}
        style={{ color: selectedIcon === "offers" ? "grey" : "black" }}
        onClick={() => handleIconClick("offers")}
      />
      <BottomNavigationAction
        label="Need Help?"
        to="/NeedHelp"
        component={Link}
        icon={<MdOutlineSupportAgent style={{ color: selectedIcon === "categories" ? "grey" : "black", fontSize: '22px' }} />}
        style={{ color: selectedIcon === "categories" ? "grey" : "black" }}
        onClick={() => handleIconClick("categories")}
      />
    </BottomNavigation>
  );
}

export default MobileBottomNavigation;
