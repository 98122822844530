import React, { useEffect } from "react";
import { BrowserRouter as Router,  Routes ,Route} from "react-router-dom";
import Login from "./components/user/Auth/LoginForm/Login.jsx";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./action/UserAuthActions.js";
import Home from "../src/components/Home/Home.jsx";
import SidebarAdmin from "./Admin/sidebar/sidebar.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import FrequentlyQuestions from "./components/user/pages/frequentlyQuestions/frequentlyQuestions.jsx";
import TermsAndConditions from "./components/user/pages/TermsConditions/terms&Conditions.jsx";
import "./components/user/common/commonStyle.css";
import PrivacyAndPolicy from "./components/user/pages/privacyPage/privacy&Policy.jsx";
import Categories from "./components/user/pages/categories/categories.jsx";
import "./App.css"
import Address from "./components/user/pages/addressBook/manageaddress.jsx";
import BookingHistory from "./components/user/pages/bookingHistory/BookingHistory.jsx";
import EditProfile from "./components/user/mobile/profle/editProfile/editProfile.jsx";
import ProfilePage from "./components/user/mobile/profle/profile.jsx";
import NeedHelp from "./components/user/mobile/needHelp/needHelp.jsx";
import AboutUs from "./components/user/pages/aboutUs/aboutus.jsx";





// ============= Admin =============//
import Category from "./Admin/categoryMaster/category/category.jsx";
import SubCategory from "./Admin/categoryMaster/subCategory/subcategory.jsx";
import Homeadmin from "./Admin/Home/Home.jsx";
import Services from "./components/user/pages/Services/Services.jsx";
import Blog from "./Admin/blogs/blog.jsx";
import Faq from "./Admin/Faq/Faq.jsx";
import LandingBanner from "./Admin/herobanner/landingBg.jsx";
import UserBooking from "./Admin/Bookings/Bookings.jsx";


function App() {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token")
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  useEffect(() => {
    // Reload the page when user's role changes
    if (user && user.user && user.user.role) {
      // window.location.reload();
    }
  }, [user]);

  return (
    user?.user?.role === "admin"?<>
    <Router>
      <SidebarAdmin user={user} />
      <Routes>
        <Route path="/" element={<ProtectedRoute isAdmin={true} element={Homeadmin}
        />} />
         <Route path="/login" element={<Login />} />
         <Route path="/Category" element={<Category />} />
         <Route path="/SubCategory" element={<SubCategory />} />
         <Route path="/Blog" element={<Blog/>}/>
         <Route path="/Faq" element={<Faq/>}/>
         <Route path="/LandingBanner" element={<LandingBanner/>}/>
         <Route path="/UserBooking" element={<UserBooking/>}/>
         
      </Routes>
 
    </Router>
    </>
    :

    <>
    <Router>
      {/* <Navigation user ={user}/> */}
      {/* <MobileBottomNavigation/> */}
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/category/:id" element={<Services />} />
      <Route path="/FrequentlyQuestions" element={<FrequentlyQuestions/>}/>
      <Route path="/PrivacyAndPolicy" element={<PrivacyAndPolicy/>}/>
      <Route path="/TermsAndConditions" element={<TermsAndConditions/>}/>
      <Route path="/Categories" element={<Categories/>}/>
      <Route path="/Address" element={<Address/>}/>
      <Route path="/BookingHistory" element={<BookingHistory/>}/>
      <Route path="/EditProfile" element={<EditProfile/>}/>
      <Route path="/ProfilePage" element={<ProfilePage/>}/>
      <Route path="/NeedHelp" element={<NeedHelp/>}/>
      <Route path="/AboutUs" element={<AboutUs/>}/>

      </Routes>
      
    </Router>
    </>
  );
}


export default App;
