import { Box, Tab, Tabs } from "@mui/material";
import '../common/commonStyle.css'
import TopNav from "../sidebar/navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import CreateBlog from "./CreateBlog";
import PreviewBlog from "./blogpreview";


const Blog = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <TopNav />
      <Box height={70} />
      {loading ? (
        <div className="loading">
           <div className="loader"></div>
        </div>
      ) : (
        <div >
          <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box component="main" sx={{ flex: 1, p: 3 }}>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
              >
                <Tab label="Create Blog" style={{ textTransform: "capitalize", fontSize:"12px"}} />
                <Tab label="Preview Blog" style={{ textTransform: "capitalize" ,fontSize:"12px"}} />
              </Tabs>
              {tabIndex === 0 && <CreateBlog />}
              {tabIndex === 1 && <PreviewBlog />}
            </Box>
          </Box>
        </div>
      )}
        
    </>
  );
}

export default Blog;
