import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useAppStore } from '../appstore';
import Profile from "../sidebar/Profile";
import NotificationsIcon from '@mui/icons-material/Notifications';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Tooltip from '@mui/material/Tooltip';
import ThemeToggle from '../theme/themeToggle';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const handleFullScreen = () => {
  if (document.fullscreenEnabled) {
    document.documentElement.requestFullscreen();
  }
};

export default function TopNav({ theme, toggleTheme }) {
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" style={{
        background: 'white',
        height: "70px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
        
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => { updateDopen(!dopen) }}
          >
            <MenuIcon style={{ color: "#422afb", padding: "6px", borderRadius: "10px", background: "rgb(198, 215, 253" }} />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color='black'
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Door Plus
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <div style={{
            position: 'relative',
          }}>
            <input
              type="text"
              placeholder="Search..."
              style={{
                width: '100%',
                padding: '15px 40px 15px 10px',
                boxSizing: 'border-box',
                border: '2px solid #c6d7fd',
                borderRadius: '5px',
                outline: 'none',
              //  boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
              }}
            />
            <SearchIcon
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#422afb'
              }}
            />
          </div>
          <Box sx={{ flexGrow: 8 }} />
          <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          <Tooltip title="Full Screen">
            <FullscreenIcon
              style={{
                margin: "0px 10px",
                fontSize: "25px",
                cursor: "pointer",
                color:'422afb',
                background:'#c6d7fd',
                padding:"5px",
                borderRadius:"30px"
              }}
              onClick={handleFullScreen}
            />
          </Tooltip>
          <Tooltip title="Notification">
            <NotificationsIcon
              style={{
                margin: "0px 10px",
                fontSize: "25px",
                cursor: "pointer",
                color:'422afb',
                background:'#c6d7fd',
                padding:"5px",
                borderRadius:"30px"
              }}
            />
          </Tooltip>
          <Profile />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
