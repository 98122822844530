import axios from "axios";
import siteConfig from "../siteConfig";
import {
    FAQ_REQUEST,
    FAQ_SUCCESS,
    FAQ_FAIL,
    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAIL,
    DELETE_FAQ_REQUEST,
    DELETE_FAQ_SUCCESS,
    DELETE_FAQ_FAIL,
} from "../constant/FaqConstant";

export const FaqCreate = (FaqData) => async (dispatch) => {
    try {
        dispatch({ type: FAQ_REQUEST });
        //  const token = localStorage.getItem('token');
        // console.log(token, "hhhhgghgh");
        const config = {
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem("token"),
            }
        };
        const { data } = await axios.post(
            `${siteConfig.base_URL}/api/v1/FrequentlyQuestion`,
            FaqData,
            config
        );
        dispatch({ type: FAQ_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FAQ_FAIL, payload: error.message });
    }
};
export const Frequentlyans = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_FAQ_REQUEST

        })
        const token = localStorage.getItem('token')
        console.log(token, "hhhjhj")
        const config = {
            Headers: {
                'content-type': 'application/json',
                token: token
            }
        }
        const { data } = await axios.get(
            `${siteConfig.base_URL}/api/v1/AllFaQ`,
            config
        )
        dispatch({ type: GET_FAQ_SUCCESS, payload: data })
    } catch (error) {
        dispatch({ type: GET_FAQ_FAIL, payload: error.message })
    }
}


export const deleteFaq = (_id) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FAQ_REQUEST
        })
        const token = localStorage.getItem('token')
        const config = {
            headers: {
                'content-type': 'application/json',
                token: token
            }
        }
        const { data } = await axios.delete(
            `${siteConfig.base_URL}/api/v1/FrequentlyQuestion/${_id}`,
            config
        )
        dispatch({
            type: DELETE_FAQ_SUCCESS, payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_FAQ_FAIL, payload: error.message
        })
    }
}