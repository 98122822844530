import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Plumbing.css";
import { useDispatch, useSelector } from "react-redux";
import {
  perticularSubCetegory,
} from "../../../../action/subCategory";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ArrowForward } from "@mui/icons-material";


const Plumbing = () => {
  const dispatch = useDispatch();
  const [subcategory, setSubcategory] = useState("");
  const { fetchAllSubCategory } = useSelector(
    (state) => state.fetchAllSubCategory
  );

  console.log(fetchAllSubCategory, "mmmm");
  console.log(subcategory, "hjhjhj");
  useEffect(() => {
    dispatch(perticularSubCetegory("6662c6377814881072725b5e"));
  }, [dispatch]);

  console.log(fetchAllSubCategory, "kjjkjk");
  useEffect(() => {
    if (fetchAllSubCategory) {
      setSubcategory(fetchAllSubCategory);
    }
  }, [fetchAllSubCategory]);


  return (
    <Box style={{ marginTop: "5px" }}>
      <Box style={{ padding: "30px" }} className='mediaPlumbpd'>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: "12px" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: "9px" }}
            >
              <Grid item xs={6}>
                <Typography style={PlumbingStyle.PlumbingServiceHeading} className="medaplumhead">Plumbing</Typography>
                <Typography style={PlumbingStyle.ChlidParagraph} className="plumchildp">
                  Door Plus Providing Low Coast Plumbing Service
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <Typography style={PlumbingStyle.viewAllStyle} className="mobilemediaview">
                  <span
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      padding: "14px",
                      cursor:'pointer'
                    }}
                  >
                    view all <ArrowForward style={{ fontSize: "13px" }} className="doorplusviewicon" />
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {subcategory?.getsubcategory?.length > 0 ? (
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                infinite={true}
              >
                {subcategory?.getsubcategory?.map((subcategory) => (
                  <Box>
                    <Box
                      key={subcategory._id}
                      style={PlumbingStyle.ImageContainer}
                    >
                      <div style={PlumbingStyle.ZoomContainer}>
                        <img
                          src={subcategory.image.url}
                          style={PlumbingStyle.CardStyle}
                          className="mediaplumImg"
                        />
                      </div>
                      <div style={PlumbingStyle.btnBookNowStyle}>
                        Book Now
                      </div>
                    </Box>
                    <Typography style={PlumbingStyle.ServiceTitle}>
                      {subcategory.SubcategoryTitle}
                    </Typography>
                  </Box>
                ))}
              </Carousel>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Plumbing;

const PlumbingStyle = {
  PlumbingServiceHeading: {
    fontSize: "32px",
    fontWeight: 700,
    color: "black",
    textTransform: "capitalize",
    marginBottom: "4px",
  },
  ServiceTitle: {
    textAlign: "justify",
    marginTop: "2px",
    paddingLeft:"5px",
    fontSize: "15px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
  viewAllStyle: {
    fontSize: "15px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",

  },
  CardStyle: {
    borderRadius: "5px",
    height: "240px",
    cursor: "pointer",
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    objectFit: "cover",
  },
  ImageContainer: {
    position: "relative",
    margin: "5px",
    overflow: "hidden",
  },
  btnBookNowStyle: {
    position: "absolute",
    bottom: 14,
    left: 8,
    fontSize: "16px",
    fontWeight: "500",
    color: "white",
    textTransform: "capitalize",
    padding: "10px",
    backgroundColor: "rgb(0 0 0 / 49%)",
    borderRadius: "5px",
    cursor: "pointer",
  },
  CardlastTextStyle: {
    position: "absolute",
    left: 10,
    bottom: 19,
    fontSize: "17px",
    fontWeight: "300",
    color: "white",
    textTransform: "capitalize",
    padding: "10px",
  },
  ChlidParagraph: {
    fontSize: "13px",
    fontWeight: "400",
    color: "black",
    textTransform: "capitalize",
  },
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
