import React from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Avatar,
    Container,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import MobileBottomNavigation from '../../mobilebottomnav/bottomnav';

const EditProfile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <React.Fragment>
            <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'white',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    position: 'sticky',
                    top: 0,
                    zIndex: 10
                }}>
                    <IconButton component={Link} to="/ProfilePage" edge="start" sx={{ mr: 1, color: 'black', }}>
                        <ArrowBack style={{ fontSize: '1rem', fontWeight: 500, }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '1rem', fontWeight: 500, background: 'black', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        Edit Profile
                    </Typography>
                </Box>

                <Container maxWidth="sm" disableGutters={isMobile} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', pt: '1px' }}>
                    <Box sx={{
                        p: 2, bgcolor: 'white', mb: 8, width: '87%', mt:2,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        border: 'none',
                        borderRadius: 5,
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-2px)',
                        },
                    }}>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <Avatar
                                src="/path-to-avatar-image.jpg"
                                sx={{ width: 80, height: 80 }}
                            />
                            <IconButton
                                sx={{
                                    position: 'absolute',
                                    right: 'calc(52% - 50px)',
                                    bottom: -2,
                                    bgcolor: 'white',
                                    boxShadow: 1,
                                    p: '4px',
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <Typography variant="h6" align="center" sx={{ mb: 3 }}>
                            guest user
                        </Typography>

                        <Typography variant="subtitle2" sx={{ mb: 2, color: 'text.secondary' }}>
                            General Information
                        </Typography>

                        <form>
                            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Name</Typography>
                                    <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Last Name</Typography>
                                    <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                                </Box>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Email</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="body2" sx={{ mb: 0.5, color: '#333333', fontWeight: 500 }}>Mobile Number</Typography>
                                <TextField fullWidth variant="outlined" size="small" InputProps={{ sx: inputStyle }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 3 }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        flex: 1,
                                        color: '#000000',
                                        borderColor: '#000000',
                                        '&:hover': { bgcolor: '#F5F5F5' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        py: 1.5,
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        flex: 1,
                                        bgcolor: '#000000',
                                        '&:hover': { bgcolor: '#333333' },
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        py: 1.5,
                                    }}
                                >
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Container>
            </Box>
            <MobileBottomNavigation />
        </React.Fragment>

    );
}

export default EditProfile;

const inputStyle = {
    bgcolor: 'white',
    '&:hover': { bgcolor: 'white' },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#BDBDBD',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000000',
    },
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
};
