import React from "react";
import Banner from "../user/pages/banner/banner.jsx";
import Allcategory from "../user/pages/allCategory/Allcategory.jsx";
import Navigation from "../user/inc/navigationBar/navigation.jsx";
import Newinstall from "../user/pages/newInstallnation/newinstall.jsx";
import Footer from "../user/inc/Footer/footer.jsx";
//import Plumbing from "../user/pages/Plumbing/Plumbing.jsx";
import FurnitureRepair from "../user/pages/furnitureRepair/furnitureRepair.jsx";
import PreviewLandingHerobg from "../user/pages/Plumbing/Plumbing.jsx";
import MobileBottomNavigation from "../user/mobile/mobilebottomnav/bottomnav.jsx";

const Home = () => {
  return (
    <>

      <Navigation />
      <Banner />
      <Allcategory />
      <Newinstall />
      {/* <Plumbing /> */}
      <PreviewLandingHerobg />
      <FurnitureRepair />
      <MobileBottomNavigation />
      <Footer />
    </>
  );
};

export default Home;
