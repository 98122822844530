import React from 'react';
import { Box, IconButton } from '@mui/material';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

const style = {
  backButton: {
    position: 'fixed',
    width:"100%",
    top: 0,
    left: 0,
    zIndex: 2,
    padding: '5px',
    
    backdropFilter: 'blur(4px)',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 6px 0px',
    backgroundImage: "linear-gradient(to left, #000000, rgba(0, 0, 0, 0.5))",
  },
};

const BackButton = () => {
  return (
    <Box sx={style.backButton}>
      <IconButton component={Link} to="/" sx={{ color: 'white' }}>
        <IoIosArrowBack size={24} />
      </IconButton>
    </Box>
  );
};

export default BackButton;
