export const BLOG_REQUEST = 'BLOG_REQUEST';
export const BLOG_SUCCESS = 'BLOG_SUCCESS';
export const BLOG_FAIL = 'BLOG_FAIL';
export const BLOG_RESET = 'BLOG_RESET';

export const GET_BLOG_REQUEST = 'GET_BLOG_REQUEST';
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL';
export const GET_BLOG_RESET = 'GET_BLOG_RESET';

export const UPDATE_BLOG_REQUEST = 'UPDATE_BLOG_REQUEST';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL';
export const UPDATE_BLOG_RESET = 'UPDATE_BLOG_RESET';

export const DELETE_BLOG_REQUEST = 'DELETE_BLOG_REQUEST';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const DELETE_BLOG_RESET = 'DELETE_BLOG_RESET';
export const DELETE_BLOG_FAIL = 'DELETE_BLOG_FAIL';