
import { Link } from "react-router-dom";
import React from 'react'
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton } from '@mui/material'
import { IoIosArrowBack } from 'react-icons/io';

const Categories = () => {
    return (
        <React.Fragment>
            <Box sx={style.backButton}>
                <Button component={Link} to="/" sx={{ color: 'white' }}>
                    <ArrowBackIcon  style={{ color: "#bebaba" ,fontSize:"19px" }} />
                    <p style={{color:"#bebaba" ,fontSize:"12px", textTransform:"capitalize"}}>Categories</p>
                </Button>
            </Box>
    
            <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f5f5f5' }}>
                <h2 style={{ color: '#333', fontSize: '24px', marginBottom: '20px',  fontWeight:600 ,textTransform:"capitalize"}}>choose your categories</h2>
            </div>
            <MobileBottomNavigation />
        </React.Fragment>

    )
}

export default Categories



const style = {
    backButton: {
        padding: '5px',
        backdropFilter: 'blur(4px)',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 6px 0px',
        backgroundImage: "white",

    },
};