import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableContainer,
    TablePagination
} from '@mui/material';
import Sidebar from "../sidebar/sidebar";
import TopNav from '../sidebar/navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBookings } from '../../action/BookingAction';
import SearchBar from '../sidebar/searchbar';

const UserBooking = () => {
    const [loading, setLoading] = useState(true);
    const { getBooking } = useSelector((state) => state.getBooking);
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        dispatch(getUserBookings());
    }, [dispatch]);

    useEffect(() => {
        const PageLoad = setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => clearTimeout(PageLoad);
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <TopNav />
            {loading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    <Box height={90} />
                    <div>
                        <Box sx={{ display: 'flex' }}>
                            <Sidebar />
                            <Box component="main" sx={{ flex: 1, p: 3 }}>
                                <Container>
                                    <Box sx={{ margin: "15px 0px" }}>
                                        {/* <SearchBar /> */}
                                    </Box>
                                    <Paper
                                    style={{
                                        boxShadow:"rgba(17, 17, 26, 0.1) 0px 0px 16px"
                                        
                                    }}
                                     sx={{ padding: 2, borderRadius: 0 }}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead sx={{ backgroundColor: 'rgba(66, 42, 251, 0.1)' }}>
                                                    <TableRow>
                                                        <TableCell sx={{ color: '#422afb', fontWeight: 'bold', fontSize:"11px" , padding: '15px', borderBottom: '2px solid #422afb' }}>Serial Number</TableCell>
                                                        <TableCell sx={{ color: '#422afb', fontWeight: 'bold',fontSize:"11px" , padding: '15px', borderBottom: '2px solid #422afb' }}>Name</TableCell>
                                                        <TableCell sx={{ color: '#422afb', fontWeight: 'bold',fontSize:"11px" , padding: '15px', borderBottom: '2px solid #422afb' }}>LastName</TableCell>
                                                        <TableCell sx={{ color: '#422afb', fontWeight: 'bold',fontSize:"11px" , padding: '15px', borderBottom: '2px solid #422afb' }}>Mobile Number</TableCell>
                                                        <TableCell sx={{ color: '#422afb', fontWeight: 'bold',fontSize:"11px" , padding: '15px', borderBottom: '2px solid #422afb' }}>Created Date</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {getBooking?.data
                                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((bookings, index) => (
                                                            <TableRow
                                                                key={bookings._id}
                                                                sx={{
                                                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                                                    '&:hover': {
                                                                        backgroundColor: '#f1f1f1',
                                                                        cursor: 'pointer',
                                                                    },
                                                                    borderBottom: '1px solid #dbdbdb',
                                                                }}
                                                            >
                                                                <TableCell sx={{ padding: '15px' ,fontSize:"11px" }}>{index + 1 + page * rowsPerPage}</TableCell>
                                                                <TableCell sx={{ textTransform: "capitalize", padding: '15px', fontSize:"11px" }}>{bookings.Booking.name}</TableCell>
                                                                <TableCell sx={{ textTransform: "capitalize", padding: '15px' ,fontSize:"11px" }}>{bookings.Booking.lastname}</TableCell>
                                                                <TableCell sx={{ textTransform: "capitalize", padding: '15px' ,fontSize:"11px" }}>{bookings.Booking.mobile}</TableCell>
                                                                <TableCell sx={{ textTransform: "capitalize", padding: '15px' ,fontSize:"11px" }}>{bookings.Booking.createdDate}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={getBooking?.data?.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Container>
                            </Box>
                        </Box>
                    </div>
                </>
            )}
        </>
    );
};

export default UserBooking;
