import { CREATE_LANDING_PAGE_FAILURE, CREATE_LANDING_PAGE_REQUEST, CREATE_LANDING_PAGE_SUCCESS, GET_LANDING_PAGE_FAILURE, GET_LANDING_PAGE_REQUEST, GET_LANDING_PAGE_SUCCESS, RESET_LANDING_PAGE_STATUS } from "../constant/LandingBannerConst";

export const CreateLanding = (
    state = { landingBg: {}, loading: false },
    action
  ) => {
    switch (action.type) {
      case CREATE_LANDING_PAGE_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false,
        };
      case CREATE_LANDING_PAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          landingBg: action.payload,
          isAuthenticated: true,
        };
      case RESET_LANDING_PAGE_STATUS:
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
        };
      case CREATE_LANDING_PAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          landingBg: null,
        };
      default:
        return state;
    }
  };

  export const GetLandingReducer = (state = { getlanding: [] }, action) => {
    switch (action.type) {
      case GET_LANDING_PAGE_REQUEST:
        return {
          loading: true,
          getlanding: [],
        };
      case GET_LANDING_PAGE_SUCCESS:
        return {
          loading: false,
          getlanding: action.payload,
        };
      case GET_LANDING_PAGE_FAILURE:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };