import { Box, Tab, Tabs } from "@mui/material";
import '../common/commonStyle.css'
import TopNav from "../sidebar/navbar";
import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import CreateBanner from "./LandingHerobg";
import PreviewBanner from "./previewLandingHerobg";
import LandingHerobg from "./LandingHerobg";
import PreviewLandingHerobg from "./previewLandingHerobg";


const LandingBanner = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const pageload = setTimeout(() => {
      setLoading(false)
    }, 500);
    return () => clearTimeout(pageload)
  })

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <>
      <TopNav />
      <Box height={70} />

      {
        loading ?
          <div className="loading">
            <div className="loader"></div>
          </div> :

          <div >
            <Box sx={{ display: 'flex' }}>
              <Sidebar />
              <Box component="main" sx={{ flex: 1, p: 3 }}>
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                >
                  <Tab label="Create Landing background" style={{ textTransform: "capitalize", fontSize: "12px" }} />
                  <Tab label="Preview Landing background" style={{ textTransform: "capitalize", fontSize: "12px" }} />
                </Tabs>
                {tabIndex === 0 && <LandingHerobg />}
                {tabIndex === 1 && <PreviewLandingHerobg />}
              </Box>
            </Box>
          </div>
      }

    </>
  );
}

export default LandingBanner;

