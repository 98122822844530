import React, { useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, 
 
  TextField,
  InputAdornment,
  Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import backgroundImage from "../../../../assets/1920.jpg";
import BackButton from '../backbutton/backButton';
import { useDispatch, useSelector } from 'react-redux';
import { Frequentlyans } from '../../../../action/FaqActions';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const FrequentlyQuestions = () => {
const dispatch = useDispatch()
  const {getFaq} = useSelector((state)=>state.getFaq)

  React.useEffect(()=>{
    dispatch(Frequentlyans())
  },[dispatch])
  const [searchQuery, setSearchQuery] = useState("");



  // const filteredFaqs = faqs.filter(faq => 
  //   faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || 
  //   faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  return (
    <React.Fragment>
      <BackButton />
      <Box style={style.heroSection} className="doorplusFaqimg">
        <div style={style.overlay}></div>
        <div style={style.heroText} className='faqDoorplusheading'>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            className="doorplusFaqheading"
          >
            Frequently Asked Questions
          </Typography>
        </div>
        <Box style={style.SearchBox} className="doorplus-mobile">
          <TextField
            variant="outlined"
            placeholder="Search your Questions"
            style={style.searchInput}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={style.iconButton}
                    aria-label="search"
                    className="doorplussearchiconmb"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Container style={{ marginTop: '120px', color: 'black', maxWidth: '950px', marginBottom: '50px' }} className='faqcondoorplus'>
       {
        getFaq?.data?.map((faq,index)=>{
          return(

            <Accordion key={index} style={{ backgroundColor: 'white', color: 'black', marginBottom: '15px', padding: '12px', boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
              <AccordionSummary
                expandIcon={<AddIcon style={{ color: 'black' }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography style={{ fontWeight: 500, fontSize: '15px' }}>{faq.Faq.Questions}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontWeight: 300, fontSize: '12px' }}>
                  {faq.Faq.Answers}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
       }
       
      <MobileBottomNavigation/>
      </Container>
    </React.Fragment>
  );
}

export default FrequentlyQuestions;

const style = {
  heroSection: {
    position: "relative",
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 65.1%), url(${backgroundImage})`,
    WebkitBackgroundSize: "100%",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
    color: "white",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    content: "",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(to left, #000000, rgba(0, 0, 0, 0.5))",
  },
  heroText: {
    padding: "25px",
    zIndex: 1,
    marginTop:"40px"
  },
  SearchBox: {
    position: "absolute",
    bottom: "-16%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "35px 15px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "70%",
  },
  searchInput: {
    flex: 1,
    marginRight: "10px",
    borderRadius: "5px",
  },
  iconButton: {
    padding: "10px",
    borderRadius: "5px",
    color: "black",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
};
