export const CATEGORY_REQUEST = 'CATEGORY_REQUEST';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const CATEGORY_FAIL = 'CATEGORY_FAIL';
export const CATEGORY_RESET = 'CATEGORY_RESET';

export const ALL_CATEGORY_REQUEST = 'ALL_CATEGORY_REQUEST';
export const ALL_CATEGORY_SUCCESS = 'ALL_CATEGORY_SUCCESS';
export const ALL_CATEGORY_FAIL = 'ALL_CATEGORY_FAIL';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
export const DELETE_CATEGORY_RESET = 'DELETE_CATEGORY_RESET';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';
export const UPDATE_CATEGORY_RESET = 'UPDATE_CATEGORY_RESET';


