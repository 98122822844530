import React from 'react';
import {
  Container, Typography, Box, Paper, Button,
} from '@mui/material';
import backgroundImage from "../../../../assets/1920.jpg";
import BackButton from '../backbutton/backButton';
import MobileBottomNavigation from '../../mobile/mobilebottomnav/bottomnav';

const PrivacyAndPolicy = () => {
  return (
    <React.Fragment>
      <BackButton />
      <Box sx={style.heroSection} className="doorplusFaqimg">
        <div style={style.overlay}></div>
        <Box sx={style.heroText} className='faqDoorplusheading'>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            className="doorplusFaqheading"
          >
            Privacy Policy
          </Typography>
        </Box>
      </Box>
      <Container sx={containerStyle}>
        <Paper elevation={3} sx={paperStyle} className='doorplus-termmb'>
          <Typography variant="h4" gutterBottom sx={headingStyle}>
            Privacy Policy
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            Welcome to DoorPlus. This privacy policy outlines how we collect, use, and protect your information when you use our service.
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            By accessing or using our service, you agree to the terms of this privacy policy. If you do not agree with any part of this policy, please do not use our service.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Information Collection
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            We collect various types of information to provide and improve our service to you. This includes information you provide directly, such as when you create an account, and information collected automatically, such as usage data.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Use of Information
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            The information we collect is used to provide, maintain, and improve our services. This includes using data to personalize your experience, communicate with you, and for security purposes.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Sharing of Information
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights. We may share non-personal information, such as aggregated user statistics, with third parties.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Data Security
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            We implement security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Your Choices
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            You have choices regarding the information you provide to us. You can update your account information, opt-out of marketing communications, and request the deletion of your account.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Changes to This Policy
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this policy periodically for any changes.
          </Typography>
          <div style={dividerStyle} />
          <Typography variant="h6" gutterBottom sx={subheadingStyle}>
            Contact Us
          </Typography>
          <Typography paragraph sx={paragraphStyle}>
            If you have any questions about this privacy policy, please contact us at support@doorplus.com.
          </Typography>
        </Paper>
      </Container>
      <MobileBottomNavigation />
    </React.Fragment>
  );
}

export default PrivacyAndPolicy;

const style = {
  heroSection: {
    position: "relative",
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 65.1%), url(${backgroundImage})`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
    color: "white",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  heroText: {
    position: "relative",
    zIndex: 2,
    padding: "20px",
  },
};

const containerStyle = {
  marginTop: '2rem',
  marginBottom: '5rem',
  padding: '20px',
};

const paperStyle = {
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: '#fff',
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
};

const headingStyle = {
  color: '#333',
  fontSize: '26px',
  marginBottom: '20px',
};

const paragraphStyle = {
  color: '#555',
  fontSize: '14px',
  marginBottom: '15px',
  lineHeight: '1.6',
};

const subheadingStyle = {
  color: '#333',
  fontSize: '20px',
  marginBottom: '15px',
};

const dividerStyle = {
  marginTop: '20px',
  marginBottom: '20px',
  backgroundColor: '#ddd',
  height: '1px',
};
