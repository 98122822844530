import React from "react";
import backgroundImage from "../../../../assets/navimg.jpg";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Icon,
} from "@mui/material";

import { VerifiedUser, HelpOutline } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import "./bannermobile.css";

const HeroSection = () => {
  return (
    <>
      <Box style={style.heroSection} className="doorplusheroimg">
        <div style={style.overlay}></div>
        <div style={style.heroText}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            className="doorplusheroheading"
          >
            Welcome to DoorPlus
          </Typography>
          <Typography variant="body1" className="doorplusherosubheading" gutterBottom>
            Welcome to the world of home services and solutions!
          </Typography>
        </div>
        <Box style={style.SearchBox} className="doorplus-mobile">
          <TextField
            variant="outlined"
            placeholder="Search Services"
            style={style.searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={style.iconButton}
                    aria-label="search"
                    className="doorplussearchiconmb"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={style.searchButton}
            className="doorplussearchbtn"
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;

const style = {
  heroSection: {
    position: "relative",
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 65.1%), url(${backgroundImage})`,
    WebkitBackgroundSize: "100%",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "450px",
    color: "white",
    textAlign: "center",
  },
  overlay: {
    position: "absolute",
    content: "",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: "linear-gradient(to left, #000000, rgba(0, 0, 0, 0.5))",
  },
  heroText: {
    padding: "25px",
    zIndex: 1,
    marginTop:"40px"
  },
  SearchBox: {
    position: "absolute",
    bottom: "-16%",
    left: "50%",
    transform: "translate(-50%, -10%)",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "35px 15px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "70%",
  },
  searchInput: {
    flex: 1,
    marginRight: "10px",
    borderRadius: "5px",
  },
  iconButton: {
    padding: "10px",
    //  border:'1px solid black',
    borderRadius: "5px",
    color: "black",
    cursor: "pointer",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  iconButton2: {
    color: "white",
    fontSize: "10px",
  },
  searchButton: {
    padding: "15px 25px",
    backgroundColor: "black",
    borderRadius: "5px",
    color: "white",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  HighLightStyle: {
    fontSize: "10px",
    fontWeight: "500",
    color: "white",
    textTransform: "capitalize",
    padding: "7px",
    backgroundColor: "rgb(0 0 0 / 49%)",
    borderRadius: "30px",
  },
};
