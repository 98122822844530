export const FAQ_REQUEST = 'FAQ_REQUEST';
export const FAQ_SUCCESS = 'FAQ_SUCCESS';
export const FAQ_FAIL = 'FAQ_FAIL';
export const FAQ_RESET = 'FAQ_RESET';

export const  GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const  GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const  GET_FAQ_FAIL = 'GET_FAQ_FAIL';
export const  GET_FAQ_RESET = 'GET_FAQ_RESET';

export const  UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const  UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const  UPDATE_FAQ_FAIL = 'UPDATE_FAQ_FAIL';
export const  UPDATE_FAQ_RESET = 'UPDATE_FAQ_RESET';

export const  DELETE_FAQ_REQUEST = 'DELETE_REQUEST';
export const  DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const  DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';
export const  DELETE_FAQ_RESET = 'DELETE_FAQ_RESET';

