import { BLOG_FAIL, BLOG_REQUEST, BLOG_RESET, BLOG_SUCCESS, DELETE_BLOG_FAIL, DELETE_BLOG_REQUEST, DELETE_BLOG_RESET, DELETE_BLOG_SUCCESS, GET_BLOG_FAIL, GET_BLOG_REQUEST, GET_BLOG_SUCCESS } from "../constant/blogConstant";



export const BlogReducer = (
    state = { Blogs: {}, loading: false },
    action
  ) => {
    switch (action.type) {
      case BLOG_REQUEST:
        return {
          ...state,
          loading: true,
          isAuthenticated: false,
        };
      case BLOG_SUCCESS:
        return {
          ...state,
          loading: false,
          Blogs: action.payload,
          isAuthenticated: true,
        };
      case BLOG_RESET:
        return {
          ...state,
          loading: false,
          isAuthenticated: true,
        };
      case BLOG_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isAuthenticated: false,
          Blogs: null,
        };
      default:
        return state;
    }
  };

  export const getBlogsReducer = (state = { getBlogs: [] }, action) => {
    switch (action.type) {
      case GET_BLOG_REQUEST:
        return {
          loading: true,
          getBlogs: [],
        };
      case GET_BLOG_SUCCESS:
        return {
          loading: false,
          getBlogs: action.payload,
        };
      case GET_BLOG_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };


  export const DeletedBlogsReducer = (state = { DeletedBlogs: {} }, action) => {
    switch (action.type) {
      case DELETE_BLOG_REQUEST:
        return {
          ...state,
          loading: true,
          isDeleted: false
        };
      case DELETE_BLOG_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: true
        };
      case DELETE_BLOG_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      case DELETE_BLOG_RESET:
        return {
          ...state,
          isDeleted: false
        };
      default:
        return state;
    }
  };