
import { CiMenuFries } from "react-icons/ci";





import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, Typography, Avatar, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ProfilePage from '../../mobile/profle/profile';

function Navbar() {
  const isMobile = useMediaQuery('(max-width: 700px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', width: '100%' }}>
      <ProfilePage />
    </Box>
  );

  return (
    <>
      <AppBar
        style={styles.appBar}
        position="absolute"
        elevation={0}
        sx={{
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '10px 0',
        }}
      >
        <Toolbar sx={styles.toolbar}>
          {isMobile ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '9px 0px',
                  ...(isScrolled && styles.fixedMobileHeader),
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleDrawerToggle}>
                    <Avatar src="/path-to-avatar-image.jpg" sx={{ width: 40, height: 40, mr: 1 }} />
                    <Typography
                      variant="subtitle1"
                      sx={{

                        bgcolor: isScrolled ? 'black' : 'white',
                        px: 2,
                        py: 0.5,
                        borderRadius: 20,
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                        color: isScrolled ? 'white' : 'black',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      Hello !
                    </Typography>
                  </Box>
                </Box>

              </Box>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                  sx: {
                    width: '100%',
                    maxWidth: '100vw',
                  },
                }}
              >
                {drawer}
              </Drawer>

            </>
          ) : (
            <>
              <Typography component="div" style={styles.logo}>GlamUp</Typography>
              <Box>
                <Button sx={styles.navButton} component={Link} to={'/'}>Home</Button>
                <Button sx={styles.navButton}>My Bookings</Button>
                <Button sx={styles.navButton} component={Link} to={'/auth/login'}>Sign In</Button>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: isScrolled && isMobile ? '60px' : '0' }}>
        {/* Other content goes here */}
      </Box>
    </>
  );
}

export default Navbar;

const styles = {
  appBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: '8px',
    borderBottom: '1px solid',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 700,
    fontSize: '2rem',
    marginRight: '20px',
  },
  navButton: {
    fontWeight: 500,
    fontSize: '18px',
    fontFamily: "'Roboto', sans-serif",
    padding: '0.5rem 1rem',
    marginLeft: '15px',
    color: '#fff',
    marginRight: '15px',
    textTransform: 'none',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
  },
  fixedMobileHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
    zIndex: 1000,
    padding: '12px 16px',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

