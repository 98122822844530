import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaPhone, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { GiPostOffice } from "react-icons/gi";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import Logo from "../../../../assets/Logo.png"

const Footer = () => {
  return (
    <Box component="footer" bgcolor="rgb(242, 242, 242)" color="black" py={7}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3} md={3}>
            {/* <Typography variant="h5" gutterBottom mb={2} style={{ fontWeight: 800 }}>
              DoorPlus

            </Typography> */}
            <img src={Logo} alt='logo' style={{height:'48px', marginBottom:"12px"}} />
            <Typography variant="body2" style={{ fontSize: '10px', fontWeight: 300 }}>
              Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
              consectetur adipisicing elit.
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Typography mb={2} gutterBottom style={{ fontWeight: 300, fontSize: '15px' }}>
              Company
            </Typography>
            <Typography variant="body2">
              <Link to='/TermsAndConditions' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Terms Of Services</Link>
              <Link to='/PrivacyAndPolicy' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Privacy Policy</Link>
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Payment</Link>
              <Link to='/FrequentlyQuestions' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>FAQ</Link>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Typography mb={2} gutterBottom style={{ fontWeight: 300, fontSize: '15px' }}>
              About DoorPlus
            </Typography>
            <Typography variant="body2">
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Work With Us</Link>
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Blog</Link>
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Contact Us</Link>
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Report Fraud</Link>
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <Typography mb={2} gutterBottom style={{ fontWeight: 300, fontSize: '15px' }}>
              Serviceable Cities
            </Typography>
            <Typography variant="body2">
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Kanpur</Link>
              <Link to='/' style={{ textDecoration: 'none', fontSize: '10px', fontWeight: 400, color: 'black', display: 'block', marginBottom: '8px' }}>Lucknow</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography gutterBottom style={{ fontWeight: 300, marginBottom: '16px', fontSize: '15px' }}>
              Contact Us
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '8px', fontSize: '11px' }}>
              <GiPostOffice style={{ marginRight: '8px', verticalAlign: 'middle' }} /> 123 Main Street, City, State
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '8px', fontSize: '11px' }}>
              <FaPhone style={{ marginRight: '8px', verticalAlign: 'middle' }} /> (123) 456-7890
            </Typography>
            <Typography variant="body2" style={{ marginBottom: '16px', fontSize: '11px' }}>
              <MdOutlineLocalPostOffice style={{ marginRight: '8px', verticalAlign: 'middle' }} /> contact@example.com
            </Typography>
            <Box display="flex" alignItems="center">
              <FaFacebook style={{ marginRight: '16px', fontSize: '14px' }} />
              <FaTwitter style={{ marginRight: '16px', fontSize: '14px' }} />
              <FaInstagram style={{ marginRight: '16px', fontSize: '14px' }} />
              <FaLinkedin style={{ fontSize: '14px' }} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} borderTop={1} borderColor="#3f3f3f" pt={4}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} DoorPlus. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;